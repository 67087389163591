import styled from 'styled-components'
import { mainBlack, white } from 'styles/color'
import {
  spacingXl,
  spacingXxl1,
  spacingXxl4,
  spacingXxl5,
  spacingXxl6,
} from 'styles/spacing'

export const Section = styled.section`
  background-color: ${white};
  display: flex;
  align-items: center;
  padding: ${spacingXl} ${spacingXxl5};
`

export const Card = styled.div`
  background-color: #31426e;
  border-radius: 32px;
  display: flex;
  align-items: center;
  flex-flow: column;
  /* width: 30%; */
  height: 80vh;
  padding: 96px ${spacingXxl4} ${spacingXxl4} ${spacingXxl4};
  color: #f7f7f7;
`

export const Image = styled.img`
  background-color: #f7f7f7;
  width: 160px;
  border-radius: 50%;
  margin-bottom: ${spacingXxl5};
`

export const Field = styled.div`
  background-color: #31426e;
  border: 1.5px solid #f7f7f7;
  border-radius: 8px;
  height: 5vh;
  display: flex;
  align-items: center;
  margin: 0 0 10px 0;
  padding: 0 150px 0 10px;
  width: 60rem;

  :focus {
    outline: none;
  }
`
export const FieldShort = styled.div`
  background-color: #31426e;
  border: 1.5px solid #f7f7f7;
  border-radius: 8px;
  height: 5vh;
  display: flex;
  align-items: center;
  margin: 0 0 10px 0;
  padding: 0 150px 0 10px;
  width: 26rem;

  :focus {
    outline: none;
  }
`

export const Button = styled.button`
    outline: none;
    color: ${white};
    background-color: #31426e;
    border: 1.5px solid #f7f7f7;
    border-radius: 8px;
    height: 5vh;
    display: flex;
    align-items: center;
    margin: 20px 0 10px 0;
    padding: 0 150px 0 10px;
    width: 100%;


    :focus {
      outline: none;
    }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spacingXl} ${spacingXxl6} ${spacingXl} ${spacingXxl5};
  width: 70%;
`

export const Header = styled.h1`
  font-size: 36px;
  margin: 0 0 ${spacingXxl4} 0;
`

export const Content = styled.div`
  display: flex;
  justify-content: space-around;
  flex-flow: column;
  height: 100%;

  ${Field} {
    background-color: ${white};
    border: 1px solid ${mainBlack};
    margin-bottom: ${spacingXxl1};
  }
`
