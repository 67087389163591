import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SelectClassButtons = styled.div`
  display: flex;
  gap: 1rem;
  margin: 1rem 0;
`

export const SelectClassButton = styled.button`
  background: ${props => props.active === true ? '#31426e' : '#949cb3'};
  color: white;
  border-radius: .5rem;
  padding: 0.75rem 1.25rem;
  width: fit-content;
  font-size: 1.1rem;
  transition: .2s all;

  :hover{
    background-color: #455680;
  }
`

export const SelectClassWrapper = styled.div`
  gap: 1rem;
  margin: 2rem 0rem;
`

export const AttendanceLogRows = styled.div`
  h1{
    margin-top: 2rem;
  }
`