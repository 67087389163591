import { useState } from "react";
import { Formik } from "formik";

import EyeOn from "images/eyeOn.svg";
import EyeOff from "images/eyeOff.svg";
import Close from "images/closeIcon.svg";
import successIllust from "images/avatar/successillust.svg";

import {
    Card,
    Heading,
    Icon,
    Image,
    FormStyled,
    StyledInput,
    Text,
    Button,
    Error,
} from "./style";
import { getUserInfo, changePassword } from "api/auth";
import { Modal } from "semantic-ui-react";
import SuccessModal from "../../../common/Modal/SuccessModal/index";

const ChangePassword = (props) => {
    const [visibility, setVisibility] = useState(false);
    const [visibilityNew, setVisibilityNew] = useState(false);
    const [visibilityConfirm, setVisibilityConfirm] = useState(false);

    const [successOpen, setSuccessOpen] = useState(false);

    const [isInvalid, setInvalid] = useState(false);
    const [error, setError] = useState(" ");
    const [valid, setValid] = useState(false);

    const initialValues = {
        password: "",
        newpassword: "",
        confirmpassword: "",
    };

    const validatePassword = (values) => {
        if (values.newpassword.length < 6) {
            setValid(false);
            setError("Password must be more than 6 characters");
        } else if (!/[a-z]/.test(values.newpassword)) {
            setValid(false);
            setError("Password must have atleast 1 lowercase character");
        } else if (!/[A-Z]/.test(values.newpassword)) {
            setValid(false);
            setError("Password must have atleast 1 uppercase character");
        } else if (!/[0-9]/.test(values.newpassword)) {
            setValid(false);
            setError("Password must have atleast 1 number");
        } else if (values.newpassword !== values.confirmpassword) {
            setValid(false);
            setError("Password doesn't match");
        } else {
            setValid(true);
            setError("");
        }
    };

    const onFormSubmit = (values) => {
        let bnccId;
        if (valid === true) {
            getUserInfo()
                .then((res) => {
                    bnccId = res.data.id;
                    changePassword(bnccId, values.password, values.newpassword)
                        .then((res) => {
                            setInvalid(false);
                            props.closeModal();
                            setSuccessOpen(true);
                        })
                        .catch((err) => {
                            setInvalid(true);
                        });
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    };

    return (
        <>
            <SuccessModal
                openSM={successOpen}
                closeSM={() => setSuccessOpen(false)}
                image={successIllust}
                heading="Successful"
                text="Your password has been changed!"
            />
            <Modal open={props.open} size="tiny" basic>
                <Card>
                    <div>
                        <Image src={Close} onClick={props.closeModal} />
                    </div>
                    <Heading>Change Password</Heading>
                    <Text>
                        Fill in your password so we can make sure it is you.
                    </Text>
                    <Formik
                        initialValues={initialValues}
                        validate={(values) => {
                            validatePassword(values);
                        }}
                        onSubmit={(values) => {
                            onFormSubmit(values);
                        }}
                    >
                        {(props) => (
                            <FormStyled>
                                <StyledInput
                                    type={visibility ? "text" : "password"}
                                    name="password"
                                    label="Current Password"
                                    icon={
                                        <Icon
                                            src={visibility ? EyeOn : EyeOff}
                                            alt="eye icon"
                                            onClick={() =>
                                                setVisibility(!visibility)
                                            }
                                        />
                                    }
                                />
                                {isInvalid && <Error>Invalid Password</Error>}
                                <StyledInput
                                    type={visibilityNew ? "text" : "password"}
                                    name="newpassword"
                                    label="New Password"
                                    icon={
                                        <Icon
                                            src={visibilityNew ? EyeOn : EyeOff}
                                            alt="eye icon"
                                            onClick={() =>
                                                setVisibilityNew(!visibilityNew)
                                            }
                                        />
                                    }
                                />
                                <StyledInput
                                    type={
                                        visibilityConfirm ? "text" : "password"
                                    }
                                    name="confirmpassword"
                                    label="Confirm Password"
                                    icon={
                                        <Icon
                                            src={
                                                visibilityConfirm
                                                    ? EyeOn
                                                    : EyeOff
                                            }
                                            alt="eye icon"
                                            onClick={() =>
                                                setVisibilityConfirm(
                                                    !visibilityConfirm
                                                )
                                            }
                                        />
                                    }
                                />
                                <Error>{error}</Error>
                                <Button type="submit">Confirm Changes</Button>
                            </FormStyled>
                        )}
                    </Formik>
                </Card>
            </Modal>
        </>
    );
};

export default ChangePassword;
