import { Redirect, Route } from "react-router";
import { useUser } from "contexts/User";

const ProtectedRoute = ({ component: Component, type, ...rest }) => {
  const { user } = useUser();
  const accessToken = localStorage.getItem("accessToken");
  return (
    <Route
      {...rest}
      render={(props) =>
        user === type && accessToken ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default ProtectedRoute;
