import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20rem;
    .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 2.5rem;
    }
    .add-material {
        background: #31426e;
        color: white;
        border: none;
        border-radius: 0.5rem;
        padding: 0.5rem 2.5rem;
    }
    .no-materials {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 45vh;
    }
    button:hover {
        filter: brightness(90%);
        cursor: pointer;
    }
`;
