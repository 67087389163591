import { useEffect, useState } from "react";
import { Header, Input } from "semantic-ui-react";
import courses from "constants/courses";
import FilteredCardList from "components/common/CardList/FilteredCardList";
import CardList from "components/common/CardList";
import { getClassList, getPraetorianList } from "api/admin";
import { Section, Wrapper } from "./style";

const List = ({ page }) => {
  const [data, setData] = useState();
  const [searchTerm, setSearchTerm] = useState(null);

  useEffect(() => {
    const getData = async () => {
      const response =
        page === "praetorian"
          ? await getPraetorianList()
          : await getClassList();
      setData(response.data.data);
    };
    getData();
  }, [page]);

  const onSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const renderList = courses.map((course, idx) => {
    return <CardList key={idx} course={course} data={data} type={page} />;
  });

  const renderContent = () => {
    if (!searchTerm) return renderList;

    return <FilteredCardList data={data} searchTerm={searchTerm} type={page} />;
  };

  return (
    <Section>
      <Header as="h1">
        {page === "praetorian" ? "Praetorian" : "Class"} List
      </Header>
      <Input
        icon="search"
        placeholder="Search..."
        id="input"
        onChange={(e) => onSearch(e)}
      />
      <Wrapper>{data && renderContent()}</Wrapper>
    </Section>
  );
};

export default List;
