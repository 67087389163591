import api from "api";
import { getAccessToken } from "../utils/http-api";

const accessToken = getAccessToken();

export const authLogin = async (values) => {
  return await api.post(`api/auth/login`, values);
};

export const getUserInfo = async () => {
  return await api.get(`api/user`, {
    headers: {
      Authorization: accessToken,
    },
  });
};

export const getCourseName = async () => {
  return api.get(`api/auth/courses`);
};

export const changePassword = async (id, currentPassword, newPassword) => {
  return api.patch(
    `api/auth/change-password`,
    { id, currentPassword, newPassword }
  );
};
