import React, { useEffect, useState } from "react";
import ClassNotesDetail from "../ClassNotesDetail";
import { getClassNotes } from "../../../../api/member";
import {
  Container,
  StyledCard,
  StyledCardContent,
  StyledHeader,
} from "./styles";
import { getUserInfo } from "api/auth";

const ClassNotes = () => {
  const [classNotes, setClassNotes] = useState([]);
  const [haveCourse, setHaveCourse] = useState();

  useEffect(() => {
    getUserInfo().then((res) => {
      if (res.data.courseId === "empty") {
        setHaveCourse(false);
      } else {
        setHaveCourse(true);
      }
    });
  }, []);

  useEffect(() => {
    // getClassNotes("java001").then((res) => {
    //   setClassNotes(res.data);
    // });
    let notes;
    if (haveCourse) {
      notes = [
        {
          timeCreated: "14-10-2022",
          content:
            "Terimakasih, kamu sudah daftar regular class. Jadwal mu akan segera di informasikan kembali. Bila ada pertanyaan atau kendala kamu dapat kontak member care kami. Apabila ada kendala/pertanyaan, kalian dapat menghubungi kami melalui Official Account Member Care BNCC yang dapat kalian add melalui link berikut: https://bit.ly/MemberCareBNCC",
        },
      ];
    } else {
      notes = [
        {
          timeCreated: "14-10-2022",
          content:
            "Selamat datang di Leap. Apabila menemukan kendala dalam menggunakan aplikasi, silakan hubungi akun Official Member Care melalui link berikut: https://bit.ly/MemberCareBNCC",
        },
      ];
    }
    setClassNotes(notes);
  }, [haveCourse]);

  const renderClassNotesDetail = (notes) =>
    notes.map((note, idx) => <ClassNotesDetail key={idx} note={note} />);

  return (
    <StyledCard>
      <StyledCardContent>
        <StyledHeader>Class Notes</StyledHeader>
        <Container>{renderClassNotesDetail(classNotes)}</Container>
      </StyledCardContent>
    </StyledCard>
  );
};

export default ClassNotes;
