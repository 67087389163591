import styled from 'styled-components'

export const Wrapper = styled.div`
    color: #333333;
    margin: 3rem 0rem;
    margin-bottom: 0rem;

    h1{
        text-align: center;
    }

    p{
        text-align: center;
    }
`