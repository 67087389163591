import styled from 'styled-components'
import { blue, desaturatedBlue, white } from 'styles/color'
import { spacingS, spacingXl, spacingXxl1 } from 'styles/spacing'

export const Wrapper = styled.div`
  background-color: ${blue};
  color: ${white};
  padding: ${spacingXl} ${spacingXxl1};
  border-radius: 16px;
`

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`

export const Name = styled.p`
  font-size: 24px;
  margin: 0;
`

export const Text = styled.p`
  color: ${desaturatedBlue};
  font-size: 15px;
`

export const Image = styled.img`
  cursor: pointer;
  transition: all 0.25s ease;
  transform: ${({ active }) => (active ? `rotate(180deg)` : `rotate(0)`)};
`

export const Description = styled.div`
  margin-top: ${spacingS};
  height: ${({ active }) => (active ? `100%` : `0`)};
  overflow: hidden;
  transition: all 0.25s ease;

  > hr {
    border-color: ${white};
  }
`
