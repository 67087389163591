import { GridButton, GridCell, GridHeading, ProjectLink, Table, Course, Wrapper } from './ShowMemberProject.styling'
import { useState } from 'react'

const sampleCourse = [
    {
        courseName: 'UI/UX-A',
        members: [
            {
                userid: 'BNCC2101017',
                name: 'Jonathan Sanjaya',
                locationMid: 'https://www.youtube.com/',
                locationFinal: '',
            },
            {
                userid: 'BNCC2101387',
                name: 'Nicholas Satrio',
                locationMid: '',
                locationFinal: 'https://www.w3schools.com/',
            },
            {
                userid: 'BNCC2101332',
                name: 'Jason Louis Supomo',
                locationMid: '',
                locationFinal: '',
            },
        ]
    },

    {
        courseName: 'UI/UX-B',
        members: [
            {
                userid: 'BNCC2101004',
                name: 'Susanna Putri',
                locationMid: '',
                locationFinal: 'https://www.google.com/',
            },
            {
                userid: 'BNCC2101128',
                name: 'Cassandra Maya',
                locationMid: 'https://www.geeksforgeeks.org/',
                locationFinal: 'https://www.kbbi.kemdikbud.go.id/',
            },
            {
                userid: 'BNCC2101034',
                name: 'Peter Augustine',
                locationMid: 'https://www.wikipedia.org/',
                locationFinal: '',
            },
            {
                userid: 'BNCC2101009',
                name: 'Jupri Supriyadi Hartono',
                locationMid: 'https://www.binus.ac.id/',
                locationFinal: '',
            },
            {
                userid: 'BNCC2101013',
                name: 'Ridwan Setiawan',
                locationMid: '',
                locationFinal: 'https://www.technoscape.id/',
            },
        ]
    },

    {
        courseName: 'UI/UX-C',
        members: [
            {
                userid: 'BNCC2101215',
                name: 'Christopher Reynaldo',
                locationMid: '',
                locationFinal: '',
            },
            {
                userid: 'BNCC2101302',
                name: 'Mario Tanjaya',
                locationMid: '',
                locationFinal: '',
            },
            {
                userid: 'BNCC2101192',
                name: 'Raymond Williams',
                locationMid: 'https://www.launching.bncc.net/',
                locationFinal: 'https://www.bncc.net/',
            },
            {
                userid: 'BNCC2101093',
                name: 'Angeline Kusuma',
                locationMid: 'https://www.favesolution.com/',
                locationFinal: 'https://www.filemagz.com/',
            },
        ]
    },
]


const ShowMemberProject = () => {
    const initialCourseStates = Array(sampleCourse.length).fill(false)
    initialCourseStates[0] = true

    const [courseStates, setCourseStates] = useState(initialCourseStates)
    const [members, setMembers] = useState(sampleCourse[0].members)

    const changeSelectedCourse = (courseIndex) => {
        setCourseStates(courseStates.map((state, index) => state = (index === courseIndex)))
        setMembers(sampleCourse[courseIndex].members)
    }

    function compareCourse(course1, course2){
        return(
          course1 === course2? true : false
        )
    }

    function compareId(id1, id2){
        return(
            id1 === id2? true : false
        )
    }

    return (
        <Wrapper>
            {sampleCourse.map((val, index) => {
            return (
                <Course key={index} isSelected = {courseStates[index]}
                leftMost = {compareCourse(val.courseName, sampleCourse[0].courseName)}
                rightMost = {compareCourse(val.courseName, sampleCourse[sampleCourse.length - 1].courseName)}
                onClick={() => changeSelectedCourse(index)}>{val.courseName}</Course>
            )
            })}
            
            <Table>
                <tr>
                    <GridHeading width = {'67px'}>No</GridHeading>
                    <GridHeading width = {'146px'}>BNCC ID</GridHeading>
                    <GridHeading width = {'347px'}>Full Name</GridHeading>
                    <GridHeading width = {'111px'}>Mid</GridHeading>
                    <GridHeading width = {'111px'}>Final</GridHeading>
                </tr>
                {members.map((val, index) => {
                    return (
                        <tr key={index}>
                            <GridCell row = {index} width = {'67px'} isLeftMost = {true}
                                isLast = {compareId(val.userid, members[members.length - 1].userid)}>{index+1}</GridCell>
                            
                            <GridCell row = {index} width = {'146px'} isLeftMost = {false}
                                isLast = {compareId(val.userid, members[members.length - 1].userid)}>{val.userid}</GridCell>
                            
                            <GridCell row = {index} width = {'347px'} isLeftMost = {false}
                                isLast = {compareId(val.userid, members[members.length - 1].userid)}>{val.name}</GridCell>
                            
                            <GridButton row = {index} width = {'111px'} isRightMost = {false}
                                isLast = {compareId(val.userid, members[members.length - 1].userid)}>
                                    <ProjectLink isAvailable = {val.locationMid===''? false : true}
                                    href={val.locationMid}>Link</ProjectLink>
                            </GridButton>
                            
                            <GridButton row = {index} width = {'111px'} isRightMost = {true}
                                isLast = {compareId(val.id, members[members.length - 1].id)}>
                                    <ProjectLink isAvailable = {val.locationFinal===''? false : true}
                                    href={val.locationFinal}>Link</ProjectLink>
                            </GridButton>
                        </tr>
                    )
                })}
            </Table>
        </Wrapper>
    )
}
export default ShowMemberProject;