import styled from "styled-components";
import { Form } from "formik";
import { Input, Select } from "formik-semantic-ui-react";

export const Wrapper = styled.div`
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    * {
        font-family: Montserrat;
    }
    .buttons {
        display: flex;
        gap: 20px;
    }
    .buttons button {
        padding: 8px 50px;
        border-radius: 0.5rem;
        margin-top: 1rem;
    }
    .cancel-btn {
        background: transparent;
        margin-right: 0.5rem;
        border: 2px solid #31426e;
        font-weight: 600;
        color: #31426e;
    }
    .submit-btn {
        background: #31426e;
        font-weight: 500;
        color: white;
        border: 2px solid rgba(49, 66, 110, 0);
    }
    .submit-btn:hover {
        background: #3d548d;
    }
    .submit-btn-disabled {
        border: 2px solid rgba(49, 66, 110, 0);
        background: rgba(49, 66, 110, 0.3);
        color: white;
    }
    button:hover,
    input:hover,
    select:hover,
    option:hover {
        cursor: pointer;
    }
`;

export const FormStyled = styled(Form)`
    background: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #333333;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem;
    border-radius: 1rem;
    width: 50%;
    .form-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    h1 {
        margin: 0;
    }
    .close {
        border: none;
        background: transparent;
        font-size: 2rem;
    }
    .input {
        width: 100%;
    }
    label {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 1rem;
        font-weight: 600;
        color: #31426e;
    }
    .error-message {
        margin-top: 5px;
    }
`;

export const InputStyled = styled(Input)`
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid black;
    border-radius: 0.3rem;
    font-size: 1rem;
    input {
        width: 100%;
    }
`;

export const SelectStyled = styled(Select)`
    display: flex;
    flex-direction: column;
    border-radius: 0.3rem;
    font-size: 1rem;
    div[name="session"] {
        border: 1px solid black !important;
        width: 100%;
    }
`;
