import { createUserAttendance, getClassMembers } from 'api/praetorian';
import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { Wrapper } from './styles'

export default function SelectMemberAttended({title, selectingMembers, toggleSelectingMembers, classId, attendanceId}) {
  const [classMembers, setClassMembers] = useState([]);
  const [membersAttended, setMembersAttended] = useState([]);
  const initialValues = []
  const history = useHistory();

  const handleSubmit = () => {
    membersAttended.forEach((members) => {
      let attendanceStatus = members.attended ? "present" : "absent"
      createUserAttendance(attendanceId, members.id, attendanceStatus).then((res)=> {
        toggleSelectingMembers(false);
        history.push("/dashboard");
      })
    })
  }

  const onFormSubmit = () => {
    toggleSelectingMembers = false
  }

  const handleChange = (id) => {
    const updatedMembers = classMembers.map(member => {
      if(member.id === id){
        member.attended = !member.attended
      } return member
    })
    setMembersAttended(updatedMembers)
  }

  const selectAll = () => {
    const updatedMembers = classMembers.map(member => {
        member.attended = true
        return member
    })
    setMembersAttended(updatedMembers)
  }

  const deSelectAll = () => {
    const updatedMembers = classMembers.map(member => {
      if(member.attended === true){
        member.attended = false
      } return member
    })
    setMembersAttended(updatedMembers)
  }

  useEffect(() => {
    getClassMembers(classId).then((res)=>{
      setClassMembers(res.data.data.member)
    })
  }, [classId]);

  return ( selectingMembers && (
    <Wrapper>
        <h1>{title}</h1>
        <p>Participants</p>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => onFormSubmit(values)}
        >
          {(props) => (
            <form>
              <div className='form-content'>
                <div className='buttons'>
                  <button type='button' onClick={selectAll}>Select all</button>
                  <button type='button' onClick={deSelectAll}>Deselect all</button>
                </div>
                <div className='member-list'>
                  {classMembers?.map((member, index) => {
                    return <label className='member-list-item' key={index}>
                      <input 
                        type="checkbox" 
                        value={`${member.id} | ${member.name}`} 
                        checked={member.attended}
                        onClick={() => handleChange(member.id)}
                      /> 
                      {`${member.id} | ${member.name}`}
                    </label>
                  })}
                </div>
              </div>
              {
                membersAttended.length <= 0 ? 
                (<button className='btn-disabled' type='button' disabled>Make Attendance</button>) :
                (<button className='btn-enabled' type='button' onClick={() => handleSubmit()}>Make Attendance</button>)
              }
            </form>
          )}
        </Formik>
    </Wrapper>
  ))
}