export const courseResponseToPanelsTransformer = (input) => {
  return input.data.map((data) => ({
    key: data?.id,
    title: {
      content: (
        <div>
          <div>{data?.name}</div>
          <div>{data?.activeClass} class(es)</div>
        </div>
      ),
      icon: "dropdown",
    },
    content: {
      content: <span>{data?.description}</span>,
    },
  }));
};

export const courseResponseToDropdownTransformer = (input) => {
  const available = input.data.filter((input) => input.capacity > 0);
  return available.map((data) => ({
    key: data.classId,
    text: `Every ${data.day}, at ${data.beginTime} - ${data.endTime} GMT +7`,
    value: data.classId,
  }));
};
