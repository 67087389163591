import styled from 'styled-components';
import { blue, white, mainBlack } from 'styles/color';
import { spacingXxl4, spacingXxl5 } from 'styles/spacing';

export const Divider = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    z-index: 9999;
`;

export const Container = styled.div`
    background-color: ${white};
    padding: ${spacingXxl5} ${spacingXxl4} ${spacingXxl4} ${spacingXxl4};
    border-radius: 24px;
    width: 508px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
    align-items: center;
    text-align: center;
    z-index: 10000;
`;

export const Heading = styled.h2`
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: center;
    color: ${mainBlack};
`;

export const ConfirmText = styled.p`
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: ${mainBlack};
`;

export const Cancel = styled.button`
    width: 196px;
    padding: 12px ${spacingXxl4};
    border-radius: 8px;
    color: ${blue};
    background-color: ${white};
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    text-align: center;
`;

export const Logout = styled.button`
    width: 196px;
    padding: 12px ${spacingXxl4};
    border-radius: 8px;
    color: ${white};
    background-color: ${blue};
    border: 2px solid ${blue};
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    text-align: center;

`;

export const Illus = styled.img`
    width: 294px;
    height: 295px;
`;

export const ConfirmBtn =  styled.button`
    width: 380px;
    color: ${white};
    background-color: ${blue};
    border: 2px solid ${blue};
    padding: 12px ${spacingXxl4};
    border-radius: 8px;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    text-align: center;
`;