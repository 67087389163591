import { useEffect } from "react";
import { Container, Grid } from "semantic-ui-react";
import { useHistory } from "react-router";
import { isTokenExist } from "utils/http-api";

import Navbar from "components/common/Navbar";
import Footer from "components/common/Footer";
import WelcomeAndProfile from "components/common/WelcomeAndProfile";

import { AttendanceChart, ClassNotes, NextClassReminder } from "./views";

const Dashboard = () => {
  const history = useHistory();
  const setUser = (test: string | null) => { console.log(test) };

  useEffect(() => {
    setUser(localStorage.getItem('role'))
    if (!isTokenExist()) {
      history.push("/login");
    }
  }, [history, setUser]);

  return (
    <>
      <Navbar title="Home" />
      <Container>
        <Grid>
          <Grid.Row>
            <Grid.Column computer={6} mobile={16}>
              <WelcomeAndProfile
                img="https://react.semantic-ui.com/images/wireframe/square-image.png"
                name="Philips"
                course="UI/UX"
              />
            </Grid.Column>
            <Grid.Column computer={10} mobile={16}>
              <NextClassReminder />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={{marginBottom: "20px"}}>
            <Grid.Column computer={6} mobile={16}>
              <AttendanceChart />
            </Grid.Column>
            <Grid.Column computer={10} mobile={16}>
              <ClassNotes />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
      <Footer />
    </>
  );
};

export default Dashboard;
