import styled from 'styled-components'

export const Wrapper = styled.button`
  color: white;
  border-radius: 1rem;
  width: 50rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .5rem 2rem;
  margin-bottom: 1.5rem;
  background: #31426e;
  font-size: 1.25rem;
  transition: .2s all;

  p:first-of-type{
    margin-top: 1.2rem;
  }

  :hover{
    background-color: #455680;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`

export const Modal = styled.div`
  position: fixed;
  background: #000000aa;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`