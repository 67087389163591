import React from "react";
import { Wrapper } from "./styles";

const SessionBoxMember = ({ session, topic, materialLink, recordingLink }) => {
    return (
        <Wrapper>
            <div>
                <h2>Session {session}</h2>
                <div>
                    <p>{topic}</p>
                </div>
            </div>
            <div className="button-flex">
                <a href={materialLink}>Material Link</a>
                <a href={recordingLink}>Recording Link</a>
            </div>
        </Wrapper>
    );
};

export default SessionBoxMember;
