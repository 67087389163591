import LandingNavbar from "components/common/LandingNavbar";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { isTokenExist } from "utils/http-api";

import Footer from "../../components/common/Footer";
import Landing from "../../components/pages/Landing";

const LandingPage = () => {
  const history = useHistory();

  useEffect(() => {
    if (isTokenExist()) {
      history.push("/dashboard");
    }
  }, [history]);

  return (
    <>
      <LandingNavbar />
      <Landing />
      <Footer />
    </>
  );
};

export default LandingPage;
