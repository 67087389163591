const courses = [
  {
    id: "java",
    name: "Java Programming",
    desciption:
      "Disini kamu akan mempelajari dasar dari bahasa pemrograman Java dan mempelajari dasar dari Object Oriented Programming",
    image: "images/trial/java.svg",
  },
  {
    id: "fe",
    name: "Front-end Development",
    desciption:
      "Disini kamu akan mempelajari dasar dari HTML, CSS, dan JS serta beberapa framework dan library untuk membuat tampilan website yang fungsional",
    image: "images/trial/fe.svg",
  },
  {
    id: "be",
    name: "Back-end Development",
    desciption:
      "Disini kamu akan mempelajar dasar dari HTML dan CSS serta mempelajari dasar dari PHP dan framework Laravel",
    image: "images/trial/be.svg",
  },
  {
    id: "mobile",
    name: "Mobile Apps Development",
    desciption:
      "Disini kamu akan mempelajari dari dasar bagaimana membuat aplikasi mobile dari awal dengan Android Studio",
    image: "images/trial/mobile.svg",
  },
  {
    id: "uiux",
    name: "UI/UX Design",
    desciption:
      "Disini kamu akan mempelajari bagaimana membuat tampilan aplikasi yang menarik dan mudah digunakan oleh pengguna",
    image: "images/trial/uiux.svg",
  },
];

export default courses;
