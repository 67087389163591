import Navbar from 'components/common/Navbar'
import PraetorianAttendanceForm from 'components/pages/PraetorianAttendance/PraetorianAttendanceForm'
import SelectMemberAttended from 'components/pages/PraetorianAttendance/SelectMemberAttended'
import React, { useState } from 'react'
import { Wrapper } from './styles'

export default function PraetorianAttendance() {
  const [selectingMembers, setSelectingMembers] = useState(false);
  const [classId, setClassId] = useState();
  const [attendanceId, setAttendanceId] = useState();

  return (
    <>
        <Navbar title='Make Attendance'/>
        <Wrapper>
            <PraetorianAttendanceForm 
              title='Make Attendance' 
              selectingMembers={selectingMembers} 
              toggleSelectingMembers={() => setSelectingMembers(!selectingMembers)}
              setClassId={setClassId}
              classId={classId}
              setAttendanceId={setAttendanceId}
            />
            <SelectMemberAttended 
              title='Select Particpants' 
              classId={classId}
              selectingMembers={selectingMembers} 
              toggleSelectingMembers={() => setSelectingMembers(!setSelectingMembers)}
              attendanceId={attendanceId}
            />
        </Wrapper>
    </>
  )
}