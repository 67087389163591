import { getUserInfo } from 'api/auth'
import Footer from 'components/common/Footer'
import AdminNavbar from 'components/common/Navbar/Admin'
import ClassAttendance from 'components/pages/Admin/ClassAttendance/ClassAttendance'
import CourseList from 'components/pages/Admin/CourseList'
import List from 'components/pages/Admin/List'
import MemberList from 'components/pages/Admin/MemberList'
import { useEffect, useState } from 'react'
import { Redirect, Route, Switch } from 'react-router'

const AdminPage = () => {
  const [user, setUser] = useState()

  useEffect(() => {
    const getUser = async () => {
      const userInfo = await getUserInfo()
      setUser(userInfo)
    }
    getUser()
  }, [])

  useEffect(() => {
    if (user?.data?.roleId !== 'admin') return <Redirect to="/login" />
  })

  return (
    <>
      <AdminNavbar />
      <Switch>
        <Route exact path="/admin/class" render={() => <List page="class" />} />
        <Route
          exact
          path="/admin/praetorian"
          render={() => <List page="praetorian" />}
        />
        <Route exact path="/admin/member" component={MemberList} />
        <Route exact path="/admin/course" component={CourseList} />
        <Route exact path="/admin/class/:id" component={ClassAttendance} />
      </Switch>
      <Footer />
    </>
  )
}

export default AdminPage
