import styled from 'styled-components'
import { blue } from 'styles/color'

export const Wrapper = styled.nav`
  display: flex;
  align-items: center;
  padding: 1rem 4rem;
  background: white;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  height: 5rem;
  position: relative;

  a {
    padding: 0.8rem 2rem;
    color: ${blue};

    &:hover {
      font-weight: bold;
    }
  }

  @media only screen and (max-width: 1024px) {
    padding: 1rem 2rem;
  }

  @media only screen and (max-width: 992px) {
    padding: 1rem;
  }
`

export const Menu = styled.div`
  display: flex;
`

export const Other = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
`

export const Image = styled.img`
  position: absolute;
  cursor: pointer;
`

export const Heading = styled.div`
  font-size: 1.5rem;
  font-weight: 500;
  margin: auto;
`

export const Logout = styled.p`
  position: absolute;
  left: 90%;
  cursor: pointer;
  padding-right: 1rem;

  &:before {
    content: 'Logout';
  }

  @media only screen and (max-width: 768px) {
    left: 85%;
  }

  @media only screen and (max-width: 480px) {
    left: 80%;
  }
`

export const Button = styled.div`
  cursor: pointer;
`
