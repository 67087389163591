import { Modal } from "semantic-ui-react";

const ClassAttendanceModal = ({ handleClose, modalOpen, modalContent }) => {
  let attendance = modalContent.attendance ?? [];

  const handleCloseButton = () => {
    // console.log(modalContent);
    handleClose();
  };

  const renderAttendeesList = attendance.map((data) => (
    <div className="modal-content-list" key={data.userId}>
      <div className="modal-content-list-name">{data.userId}</div>
      {data.status === "present" && (
        <div className="modal-content-list-status">{data.status}</div>
      )}
      {data.status === "absent" && (
        <div className="modal-content-list-status error">{data.status}</div>
      )}
    </div>
  ));

  return (
    <Modal open={modalOpen} size="small" closeOnEscape={true} className="modal">
      <Modal.Content className="modal-content">
        <i className="close" onClick={() => handleCloseButton()}>
          ×
        </i>
        <div className="modal-container">
          <div className="modal-header">{modalContent.date}</div>
          <div className="modal-description">
            <div className="modal-description-title">
              {modalContent.id} - {modalContent.praetorianId}
            </div>
            <div className="modal-description-text">
              {modalContent.courseId} • Every {modalContent.day},{" "}
              {modalContent.beginTime} - {modalContent.endTime}
            </div>
          </div>
          <div className="modal-content">{renderAttendeesList}</div>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default ClassAttendanceModal;
