const accessToken = localStorage.getItem("accessToken");
const tokenType = `Bearer`;

export const getAccessToken = () => {
  return `${tokenType} ${accessToken}`;
};

export const setAccessToken = (token) => {
  localStorage.setItem("accessToken", token);
};

export const isTokenExist = () => {
  return localStorage.getItem("accessToken") ? true : false;
};

export const setRole = (role) => {
  return localStorage.setItem("role", role)
}