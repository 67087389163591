import styled from "styled-components";
import {
  Button,
  Card,
  Container,
  Header,
  Dropdown,
  Modal,
} from "semantic-ui-react";

export const StyledContainer = styled(Container)`
  height: 80vh;
  color: white !important;
  font-family: "Montserrat" !important;
`;

export const StyledHeader = styled(Header)`
  margin: 0.5rem;
  font-weight: 400;
  text-align: center;
  color: white !important;
  font-family: "Montserrat" !important;
`;

export const StyledButton = styled(Button)`
  background-color: white !important;
  color: #31426e !important;
  width: 100%;
  font-family: "Montserrat" !important;
  margin-top: 1rem !important;

  &:hover {
    color: white !important;
    background-color: #5a688b !important;
  }
`;

export const StyledCard = styled(Card)`
  background: #31426e !important;
  border-radius: 16px !important;
  margin: 4rem 0 !important;
  width: 100% !important;
  padding: 3rem 4rem !important;
`;

export const StyledDropdown = styled(Dropdown)`
  background: #31426e !important;
  border-color: white !important;
  width: 100% !important;
  color: white !important;

  > .text:not(.default) {
    color: white !important;
  }
`;

export const CardDescription = styled.p`
  text-align: center;
  margin-bottom: 1rem !important;
`;

export const StyledModal = styled(Modal)`
  border-radius: 32px !important;
  padding: 2rem !important;
`;

export const CloseIcon = styled.i`
  font-style: normal;
  font-weight: 300;
  font-size: 36px !important;
  right: 1rem !important;
  top: 0 !important;
  color: black !important;
  cursor: pointer;
  float: right;
`;

export const StyledModalContent = styled(Modal.Content)`
  border: none !important;
  text-align: center;
`;

export const ModalHeader = styled.h1`
  font-weight: 400;
  font-size: 24px;
  text-align: center;
  font-family: "Montserrat";
`;

export const BoldText = styled.span`
  font-weight: 600;
`;

export const BlueText = styled.span`
  color: #31426e;
`;

export const StyledModalActions = styled(Modal.Actions)`
  display: flex;
  justify-content: center;
  border: none !important;
  background: white !important;
`;

export const CancelButton = styled(Button)`
  background: white !important;
  color: #31426e !important;
  font-family: "Montserrat" !important;
  font-weight: 400 !important;
`;

export const ConfirmButton = styled(Button)`
  color: white !important;
  background: #31426e !important;
  font-family: "Montserrat" !important;
  font-weight: 400 !important;

  &:hover {
    background-color: #5a688b !important;
  }

  &:active {
    background-color: #253254 !important;
  }
`;
