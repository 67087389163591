import { useState, useEffect } from "react";
import { Wrapper } from "./styles";
import { Formik } from "formik";
import {
    getPraetorianClass,
    getPraetorianMaterialByClassId,
    updatePraetorianMaterial,
} from "api/praetorian";
import { FormStyled, InputStyled } from "./styles";
import { SelectStyled } from "./styles";
import { Error } from "components/pages/Auth/LoginForm/styles";
import SuccessModal from "components/common/Modal/SuccessModal/index";
import successImage from "images/avatar/successUp.svg";
import { SESSION_VALUE } from "constants/session";

export default function UpdateMaterial({
    closeModal,
    materialId,
    latestSession,
    latestTitle,
    latestLink,
}) {
    const [checkTitle, setCheckTitle] = useState(false);
    const [checkSession, setCheckSession] = useState(false);
    const [checkLink, setCheckLink] = useState(false);

    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [materialUpdated, setMaterialUpdated] = useState(false);

    const initialValues = {
        id: materialId,
        title: latestTitle,
        session: latestSession,
        link: latestLink,
    };

    const onFormSubmit = (values) => {
        const value = values.link;
        const regex = /^(https?:\/\/)/;
        setCheckLink(regex.test(value));

        let classId;
        getPraetorianClass().then((res) => {
            if (res.data.code === 403) {
                alert("You are not a Praetorian");
                return closeModal();
            } else {
                classId = res.data.data.filter((data) => data.termId === 3);
            }
            if (classId)
                getPraetorianMaterialByClassId(classId[0].id).then((res) => {
                    let materialsId = res.data.data.filter(
                        (data) => data.id === values.id
                    );
                    console.log(materialsId)
                    updatePraetorianMaterial(
                        materialsId[0].id,
                        values.title,
                        values.link,
                        values.session,
                        classId[0].id
                    ).then(() => {
                        setShowSuccessModal(true);
                    });
                });
                console.log(values.session)
        });
    };

    useEffect(() => {
        if (materialUpdated) {
            window.location.reload();
        }
    }, [materialUpdated]);

    return (
        <Wrapper>
            <Formik
                initialValues={initialValues}
                onSubmit={(values) => {
                    onFormSubmit(values);
                }}
            >
                {(props) => (
                    <FormStyled>
                        <div className="form-header">
                            <h1>Update Material</h1>
                            <button className="close" onClick={closeModal}>
                                X
                            </button>
                        </div>
                        <div className="input">
                            <SelectStyled
                                name="session"
                                label="Session"
                                placeholder="Select your session"
                                required
                                options={SESSION_VALUE}
                                onClick={() => setCheckSession(true)}
                            />
                            <InputStyled
                                placeholder="Enter the session topic here"
                                type="text"
                                label="Session Topic"
                                name="title"
                                required
                                onClick={() => setCheckTitle(true)}
                            />
                            <InputStyled
                                placeholder="Enter the material link here"
                                type="text"
                                label="Material Link"
                                name="link"
                                required
                                onClick={() => setCheckLink(true)}
                                onChange={(e) => {
                                    props.setFieldValue(
                                        "link",
                                        e.currentTarget.value
                                    );
                                }}
                            />
                            {!checkLink && (
                                <div className="error-message">
                                    <Error>
                                        Please enter a valid URL starting with
                                        &quot;http://&quot; or &quot;https://&quot;
                                    </Error>
                                </div>
                            )}
                        </div>
                        <div className="buttons">
                            <button
                                type="reset"
                                className="cancel-btn"
                                onClick={closeModal}
                            >
                                Cancel
                            </button>
                            {checkTitle && checkSession && checkLink ? (
                                <button type="submit" className="submit-btn">
                                    Update
                                </button>
                            ) : (
                                <button
                                    type="submit"
                                    className="submit-btn-disabled"
                                    disabled
                                >
                                    Update
                                </button>
                            )}
                        </div>
                    </FormStyled>
                )}
            </Formik>
            {showSuccessModal && (
                <SuccessModal
                    openSM={showSuccessModal}
                    closeSM={() => setMaterialUpdated(true)}
                    image={successImage}
                    heading="Material Successfully Updated!"
                    text="Hopefully your students will enjoy it!"
                />
            )}
        </Wrapper>
    );
}
