import { Wrapper } from "./styles";

const ClassInfo = ({name, subject, schedule}) => {
  return (
    <Wrapper>
      <h1>{name}&apos;s Class</h1>
      <p>{subject} • {schedule}</p>
    </Wrapper>
  );
};

export default ClassInfo;
