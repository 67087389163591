import { Card, Header } from "semantic-ui-react";
import styled from "styled-components";

export const StyledCard = styled(Card)`
  height: 100% !important;
  width: 100% !important;

  @media only screen and (max-width: 768px) {
    margin-top: 2rem !important;
  }
`;

export const StyledCardContent = styled(Card.Content)`
  padding: 2rem !important;
`;

export const StyledHeader = styled(Header)`
  font-family: "Montserrat" !important;
  color: #31426e !important;
  padding-bottom: 1.5rem !important;
  font-weight: 600 !important;
`;

export const Container = styled.div`
  margin: auto;
`;
