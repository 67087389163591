import React from 'react'
import { Link } from 'react-router-dom'
import Placeholder from 'images/landing/landing-ph.svg'
import {
  Container,
  Content,
  Image,
  Text,
  Header,
  Paragraph,
  Button,
} from './styles'

const Landing = () => {
  return (
    <Container>
      <Content>
        <Image src={Placeholder} alt="Welcome" />
        <Text>
          <Header>Welcome to Leap. First time here?</Header>
          <Paragraph>
            Take your first step in BNCC Learning and Training by registering
            yourself into the Trial Class.
          </Paragraph>
          <Link to="/login">
            <Button>Register Trial Class</Button>
          </Link>
        </Text>
      </Content>
    </Container>
  )
}

export default Landing
