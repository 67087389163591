import React from 'react'

import {
    Card,
	Heading,
	Image,
    Text,
    Button,
} from './style'
import { Modal } from 'semantic-ui-react'
import Illust from '../../../../images/avatar/successillust.svg'

const SuccessModal = (props) => {  

    return (
        <>
        <Modal open={props.openSM} size="tiny" basic>
			<Card>
				<Image
					src={Illust}
					alt="illust"
				/>
                <Heading>Successful</Heading>
                <Text>Your password has been changed!</Text>
					<Button onClick={props.closeSM}>Confirm</Button>
            </Card>
        </Modal>
        </>
    )
}

export default SuccessModal;