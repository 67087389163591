import { createAttendanceClass, getPraetorianClass } from 'api/praetorian';
import { Formik } from 'formik'
import { useEffect, useState } from 'react'
import { Wrapper } from './styles'


export default function PraetorianAttendanceForm({title, selectingMembers, toggleSelectingMembers, setClassId, classId, setAttendanceId}) {
    const [date, setDate] = useState('');
    const [schedule, setSchedule] = useState('');
    const [files, setFiles] = useState();
    var file;
    const [desc, setDesc] = useState('');
    const [scheduleList, setScheduleList] = useState();
    const [numberOfSchedules, setNumberOfSchedules] = useState(1);

    const initialValues = {
        date: '',
        description: '',
        image: '',
        classId: '',
    }

    const handleSubmit = () => {
        createAttendanceClass(date, desc, files, classId).then((res) => {
            setAttendanceId(res.data.data.id)
            toggleSelectingMembers(true)
        })
    }
    
    useEffect(() => {
        getPraetorianClass().then((res) => {
            setScheduleList(res.data.data.filter((e) => e.termId !== 1))
            setNumberOfSchedules(res.data.data.length)
        })
    }, []);

  return ( !selectingMembers && (
    <Wrapper>
        <h1>{title}</h1>
        {/* TODO: Implement Form Submission */}
        <Formik 
           initialValues={initialValues}
        >
            {(props) => (
                <form>
                   <label>
                       <p>Date</p>
                       <input 
                        type="date" 
                        name="date" 
                        required 
                        placeholder='Date' 
                        value={date} 
                        onChange={(e) => setDate(e.target.value)}
                        />
                   </label>
                   <label>
                       <p>Select Schedule</p>
                       {
                           numberOfSchedules === 1 ? 
                           (
                                <select 
                                    name="schedule" 
                                    required
                                    onClick={(e) => {setSchedule(e.target.value); setClassId(e.target.value)}}
                                    onChange={(e) => {setSchedule(e.target.value); setClassId(e.target.value)}} 
                                    value={schedule}
                                >
                                    {scheduleList?.map((session, index) => {
                                        return <option 
                                        key={index}
                                        value={session.id} 
                                        >
                                            {`${session.day},  ${session.beginTime} - ${session.endTime}`}
                                        </option>
                                    })}
                                </select>
                           ) 
                           : 
                           ( 
                               <select 
                                    name="schedule" 
                                    required 
                                    onClick={(e) => {setSchedule(e.target.value); setClassId(e.target.value)}}
                                    onChange={(e) => {setSchedule(e.target.value); setClassId(e.target.value)}} 
                                    value={schedule}
                                >
                                    {scheduleList?.map((session, index) => {
                                        return <option 
                                            key={index}
                                            value={session.id} 
                                            >
                                                {`${session.day},  ${session.beginTime} - ${session.endTime}`}
                                            </option>
                                    })}
                                </select>
                        )
                       }
                   </label>
                   <label>
                       <p>Upload Picture</p>
                       <input 
                        type="file" 
                        name="pic" 
                        accept='image/png, image/jpeg, image/jpg'
                        value={file}
                        onChange={(e) => {file = e.target.files[0]; setFiles(file)}}
                        />
                   </label>
                    <label>
                        <p>Description</p>
                        <textarea 
                            name="desc" 
                            cols="50" 
                            rows="10" 
                            placeholder='Describe'
                            onChange={(e) => setDesc(e.target.value)}
                            value={desc}
                        >
                        </textarea>
                    </label>
                    {
                        date && schedule && files && desc ?
                        (<button className='btn-enabled' type='button' onClick={() => handleSubmit()}>Continue</button>) : 
                        (<button className='btn-disabled' type='button' disabled>Continue</button>)
                    }                    
                </form>
            )}
        </Formik>
    </Wrapper>
  ))
}
