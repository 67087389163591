import React, { useMemo, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import LoginPage from "pages/LoginPage";
import RegisterClassPage from "pages/RegisterClass";
import Dashboard from "pages/Dashboard";
import MemberProjectPage from "pages/MemberProject";
import LandingPage from "pages/LandingPage";
import ErrorPage from "pages/ErrorPage";
import ProtectedRoute from "components/common/ProtectedRoute/index";
import RegisterTrialPage from "pages/RegisterTrialPage";
import PraetorianMaterialPage from "pages/PraetorianMaterial";
import ClassListPage from "pages/ClassList";
import AdminPage from "pages/Admin";
import RegisterRegularPage from "pages/RegisterRegular";
import ProfilePage from "pages/Profile";
import AttendancePage from "pages/AttendancePage";
import MemberMaterialPage from "./pages/MemberMaterial";
import { UserContext } from "components/common/Context/UserContext";
import PraetorianAttendance from "pages/PraetorianAttendance";
import AttendanceLogPage from "pages/AttendanceLog";
import { UserContextProvider } from "contexts/User";
import PraetorianProject from "pages/PraetorianProject/PraetorianProject";

const App = () => {
    const [user, setUser] = useState(null);
    const userProvider = useMemo(() => ({ user, setUser }), [user, setUser]);
    return (
        <UserContextProvider>
            <UserContext.Provider value={userProvider}>
                <BrowserRouter>
                    <Switch>
                        <Route exact path="/" component={LandingPage} />
                        <Route exact path="/login" component={LoginPage} />
                        {/* <Route exact path="/register-class" component={RegisterClassPage} /> */}
                        {/* <Route exact path="/register-trial" component={RegisterTrialPage} /> */}
                        <Route
                            exact
                            path="/register-regular"
                            component={RegisterRegularPage}
                        />
                        <Route exact path="/dashboard" component={Dashboard} />
                        <Route
                            exact
                            path="/dashboard/profile"
                            component={ProfilePage}
                        />
                        <Route
                            exact
                            path="/member/attendance"
                            component={AttendancePage}
                        />
                        <Route
                            exact
                            path="/member/material"
                            component={MemberMaterialPage}
                        />
                        {/* <Route exact path="/member/project" component={MemberProjectPage} /> */}
                        <ProtectedRoute
                            exact
                            path="/praetorian/attendance"
                            component={PraetorianAttendance}
                            type="prae"
                        />
                        <ProtectedRoute
                            exact
                            path="/praetorian/attendance/log"
                            component={AttendanceLogPage}
                            type="prae"
                        />
                        <Route
                            exact
                            path="/praetorian/material"
                            component={PraetorianMaterialPage}
                            type="prae"
                        />
                        <ProtectedRoute
                            exact
                            path="/praetorian/class"
                            component={ClassListPage}
                            type="prae"
                        />
                        <ProtectedRoute
                            path="/admin"
                            component={AdminPage}
                            type="admin"
                        />
                        <ProtectedRoute
                            exact
                            path="/praetorian/project"
                            component={PraetorianProject}
                            type="prae"
                        />
                        <Route
                            render={() => (
                                <ErrorPage
                                    errorCode={404}
                                    message="Not Found"
                                />
                            )}
                        />
                    </Switch>
                </BrowserRouter>
            </UserContext.Provider>
        </UserContextProvider>
    );
};

export default App;
