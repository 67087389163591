import { useState } from 'react'

const UploadModal = (props) => {
  const [success, setSuccess] = useState(false)
  const [uploadBtn, setUploadBtn] = useState(false)

  const updateFileName = () => {
    var input = document.getElementById('file').files.item(0)
      ? document.getElementById('file').files.item(0).name
      : 'No file were selected'
    return (document.getElementsByClassName('file-name')[0].innerHTML = input)
  }

  const removeFile = () => {
    document.getElementById('error-msg').innerHTML = ''
    document.getElementById('file').value = null
    setUploadBtn(false)
    return (document.getElementsByClassName('file-name')[0].innerHTML =
      'No file were selected')
  }

  const fileValidation = () => {
    if (!document.getElementById('file').files.item(0)) {
      setUploadBtn(false)
    } else {
      setUploadBtn(true)
    }
  }

  const upload = () => {
    if (uploadBtn) {
      setSuccess(true)
    }
  }

  return (
    <div className={`overlay ${props.bool ? '' : 'hidden'}`}>
      <div className={'modal ' + (success ? 'hidden' : '')}>
        <button
          className="close-btn"
          onClick={() => {
            props.close()
            removeFile()
          }}
        >
          x
        </button>
        <div className="content">
          <div className="title">Upload File</div>
          <form action="">
            <div className="file">File</div>
            <div className="input-wrapper">
              <span className="file-name">No file were selected</span>
              <span className="choose-btn">Choose File</span>
              <input
                id="file"
                type="file"
                onChange={() => {
                  updateFileName()
                  fileValidation()
                }}
              />
            </div>
            <span id="error-msg"></span>

            <div className="btn-wrapper">
              <div className="cancel-btn" onClick={removeFile}>
                Cancel
              </div>
              <div
                className={`upload-btn ${uploadBtn ? 'on' : 'off'}`}
                onClick={() => {
                  fileValidation()
                  upload()
                }}
              >
                Upload
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* success popup */}
      <div className={'modal ' + (success ? '' : 'hidden')}>
        <div className="content-success">
          <img src="/images/member-project/success-icon.svg" alt="" />
          <div className="title">Successful</div>
          <div className="slogan">File Uploaded Successfully</div>
          <div
            className="confirm-btn"
            onClick={() => {
              setSuccess(false)
              removeFile()
              props.close()
            }}
          >
            confirm
          </div>
        </div>
      </div>
    </div>
  )
}

export default UploadModal
