import styled from 'styled-components'
import { white } from 'styles/color'
import { spacingM, spacingXxl1 } from 'styles/spacing'

export const Section = styled.section`
  padding: 5rem;

  h1 {
    text-align: center;
    margin-bottom: 3rem;
  }
`

export const Card = styled.div`
  padding: 2rem 1rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: ${spacingM};
  padding: ${spacingXxl1} ${spacingM};
  background: ${white};
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
`
