import moment from "moment";

export const findTheNextWeekday = (dayString) => {
  const dayList = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];
  const dayPosition = dayList.indexOf(dayString?.toLowerCase()) + 1;

  //This is only for trial class time handling
  /* return moment("18 Oct 2021")
    .isoWeekday(dayPosition)
    .format("dddd, D MMMM YYYY"); */

  if (moment().isoWeekday() <= dayPosition) {
    return moment().isoWeekday(dayPosition).format("dddd, D MMMM YYYY");
  } else {
    return moment()
      .add(1, "weeks")
      .isoWeekday(dayPosition)
      .format("dddd, D MMMM YYYY");
  }
};

export const convertMilisToString = (milis) => {
  let days = Math.floor(milis.asDays());
  milis.subtract(moment.duration(days, "days"));
  let hours = milis.hours();
  milis.subtract(moment.duration(hours, "hours"));
  let minutes = milis.minutes();
  milis.subtract(moment.duration(minutes, "minutes"));

  return `${days} day(s) ${hours} hour(s) ${minutes} minute(s)`;
};

export const findCountdownDuration = (date, time = "00:00") => {
  const [hour, minute] = time.split(":");
  const classStart = moment(date).hour(hour).minute(minute);
  const now = moment();
  let duration = moment.duration(classStart.diff(now));

  if (duration < 0) {
    let started = moment.duration(now.diff(classStart));
    return `${convertMilisToString(started)} ago`;
  }

  return convertMilisToString(duration);
};
