import ContentCard from 'components/common/ContentCard'
import { Header } from 'semantic-ui-react'
import { Container, Wrapper } from '../style'

const FilteredCardList = ({ data, searchTerm, type }) => {
  const renderList = data
    .filter(({ name }) => name.toLowerCase().includes(searchTerm.toLowerCase()))
    .map((datum, idx) => {
      return <ContentCard key={idx} {...datum} type={type} />
    })

  return (
    <Container>
      <Header as="h2">Search Results</Header>
      {renderList.length > 0 ? (
        <Wrapper>{renderList}</Wrapper>
      ) : (
        <p style={{ textAlign: 'center' }}>No results were found</p>
      )}
    </Container>
  )
}

export default FilteredCardList
