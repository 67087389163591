import React, { useState, useEffect } from 'react'
import CloseIcon from 'images/closeIcon.svg'
import {
    Card,
    Heading,
    SubHeading,
    Text,
    Icon,
    Button,
    HeaderWrapper,
    ContentWrapper,
    Divider
} from './style'
import { Modal } from 'semantic-ui-react'
import { getClassMembers } from 'api/praetorian'

const ClassDetailModal = ({ open, close, classId, schedule }) => {  
    const [data, setData] = useState();
    
    useEffect(() => {
        setData([]);
        classId && getClassMembers(classId).then(res => setData(res.data.data.member));
    }, [classId]);

    return (
        <>
        <Modal open={open} size="tiny" basic>
                <Card>
                    <HeaderWrapper>
                        <Heading>{classId}</Heading>
                        <Icon src={CloseIcon} onClick={() => { close(); classId = null; }}/>
                    </HeaderWrapper>
                    <SubHeading>
                        {schedule}
                    </SubHeading>
                    <Divider />
                    <ContentWrapper>
                        {data ? data.map((e, i) => 
                            <Text key={i}>
                                {e.id}
                                &ensp;|&ensp;
                                {e.name}
                            </Text>
                        )
                        : <div>Loading</div>}
                    </ContentWrapper>
                {/* <Button onClick={close}>View Attendances</Button> */}
            </Card>
        </Modal>
        </>
    )
}

export default ClassDetailModal;