import api from ".";

import config from "../config";
import { getAccessToken } from "../utils/http-api";

const { projects } = config.urls.member;
const accessToken = getAccessToken();

export const getAvailableClass = async (courseId) => {
  return await api.get("api/member/class/register", {
    headers: {
      Authorization: accessToken,
    },
    params: {
      courseId,
    },
  });
};

export const registerRegularClass = async (userId, classId) => {
  return api.post(
    `api/member/class`,
    { userId, classId },
    {
      headers: {
        Authorization: accessToken,
      },
    }
  );
};

export const getAttendanceCount = async (id) => {
  return await api.get(`api/member/attendance-count/${id}`, {
    headers: {
      Authorization: accessToken,
    },
  });
};

export const getClassNotes = async (classId) => {
  return await api.get(`api/member/class/notes/${classId}`, {
    headers: {
      Authorization: accessToken,
    },
  });
};

export const getProjects = async () => {
  return api.get(projects.getProjects, {
    headers: {
      Authorization: accessToken,
    },
  });
};

export const getClass = async () => {
  return api.get(`api/member/class`, {
    headers: {
      Authorization: accessToken,
    },
  });
};

export const getMemberProfile = async (id) => {
  return api.get(`api/member/${id}`, {
    headers: {
      Authorization: accessToken,
    },
  });
};

export const updateMemberCourse = async (id, courseId) => {
  return api.patch(
    `api/member/course/${id}`,
    { courseId },
    {
      headers: {
        Authorization: accessToken,
      },
    }
  );
};

export const getMemberAttendance = async (id) => {
  return api.get(`api/member/attendance/${id}`, {
    headers: {
      Authorization: accessToken,
    },
  });
};

export const getMemberMaterials = async (id) => {
  return api.get(`api/member/class/materials/${id}`, {
    headers: {
      Authorization: accessToken,
    },
  });
};