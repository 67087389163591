import styled from 'styled-components'

export const OuterWrapper = styled.div`
    background: #00000085;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    position: fixed;
    top: 0;
`

export const Wrapper = styled.div`
    background: white;
    border-radius: 1rem;
    padding: 1rem 2rem;
    color: black;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: left;

    h2{
        font-size: 1.75rem;
        font-weight: 400;
        margin-bottom: 0;
        margin-top: 1rem;
    }

    h3{
        margin-top: .5rem;
        font-weight: 200;
    }

    @media (max-width: 768px) {
        width: 90%;
    }
`

export const CloseButton = styled.button`
    position: absolute;
    top: 1rem;
    right: 1rem;
`

export const Members = styled.div`
    height: 15rem;
    overflow: scroll;
    overflow-x: hidden;
`