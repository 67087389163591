import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 30%;
    margin: 3rem auto;
    padding: 0 3rem;
    border-radius: 1rem;
    box-shadow: 0px 2px 10px 0px #00000040;

    *{
        margin: 0;
        padding: 0;
    }

    h1{
        text-align: center;
        padding: 3rem;
        padding-bottom: 2rem;
    }

    input, select, textarea, options{
        border: 2px solid #4D4D4D;
        border-radius: .5rem;
        padding: .4rem;
        width: 100%;
        margin-bottom: 1rem;
    }
    
    textarea{
        resize: none;
    }

    button{
        margin-bottom: 3rem;
        color: white;
        text-align: center;
        width: 100%;
        padding: .5rem 0;
        border-radius: .5rem;
    }

    .btn-enabled{
        background: #31426E;
    }

    .btn-disabled{
        background: #828282;
    }
`