import Navbar from 'components/common/Navbar'
import { Heading, Wrapper } from './PraetorianProject.style'
import ShowMemberProject from 'components/pages/PraetorianProject/ShowMemberProject'
import Footer from 'components/common/Footer'

const PraetorianProject = () => {

  return <>
    <Navbar title = "Project"/>
    <Wrapper>
      <Heading>View Project</Heading>
      <ShowMemberProject/>
    </Wrapper>
    <Footer/>
  </>
}

export default PraetorianProject;