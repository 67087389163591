import { Formik } from 'formik'
import { useState } from 'react'

import EyeOn from 'images/eyeOn.svg'
import EyeOff from 'images/eyeOff.svg'

import {
  Card,
  Container,
  Heading,
  Icon,
  FormStyled,
  StyledInput,
  Text,
  Button,
  Error,
} from './styles'
import { authLogin } from 'api/auth'
import { setAccessToken, setRole } from 'utils/http-api'
import { useHistory } from 'react-router'

const LoginForm = () => {
  const [visibility, setVisibility] = useState(false)
  const [isInvalid, setInvalid] = useState(false)
  const history = useHistory()

  const initialValues = {
    id: '',
    password: '',
  }

  const onFormSubmit = (values) => {
    authLogin(values).then((res) => {
      const role = res.data.data.original.role
      const token = res.data?.data?.original?.access_token
      setInvalid(false)
      if (token) {
        setAccessToken(token)
        setRole(role)
        history.go(0)
      } else {
        setInvalid(true)
      }
    })
  }

  return (
    <Container>
      <Card>
        <Heading>Login to Your Account</Heading>
        <Text>Welcome to Leap</Text>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            onFormSubmit(values)
          }}
        >
          {(props) => (
            <FormStyled>
              <StyledInput type="text" name="id" label="BNCC ID" />
              <StyledInput
                type={visibility ? 'text' : 'password'}
                name="password"
                label="Password"
                icon={
                  <Icon
                    src={visibility ? EyeOn : EyeOff}
                    alt="eye icon"
                    onClick={() => setVisibility(!visibility)}
                  />
                }
              />
              {isInvalid && <Error>Invalid username or password</Error>}
              <Button type="submit">Login</Button>
            </FormStyled>
          )}
        </Formik>
      </Card>
    </Container>
  )
}

export default LoginForm
