import styled from "styled-components";
import { blue, white, mainBlack } from "styles/color";

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 24px !important;
  padding: 64px;
  background-color: ${white};

  @media only screen and (max-width: 480px) {
    box-shadow: none;
    border: none;
    padding: 0;
  }
`;

export const Heading = styled.h1`
  font-weight: bolder;
  font-size: 2rem;
  text-align: center;
  color: ${mainBlack};
  font-weight: 600;
  font-family: "Montserrat";
`;

export const Text = styled.p`
  text-align: center;
  color: ${mainBlack};
  font-weight: 500;
  font-family: "Montserrat";
`;

export const Image = styled.img`
  width: 240px;
`;

export const Button = styled.button`
  cursor: pointer;
  margin-top: 20px;
  width: 100%;
  background-color: ${blue};
  color: ${white};
  padding: 10px 20px;
  border-radius: 6px;
  outline: none;
  border: none;
  font-family: "Montserrat";

  &:hover {
    background-color: #5a688b;
  }

  &:active {
    background-color: #253254;
  }
`;