import React, { useState, useEffect } from "react";

import Footer from "../../components/common/Footer";
import Navbar from "../../components/common/Navbar/index";
import AddMaterial from "components/pages/PraetorianMaterial/MaterialModal/AddMaterial";
import SessionBoxPraetorian from "components/pages/PraetorianMaterial/SessionBoxPraetorian";
import { Wrapper } from "./styles";
import { Box, Tabs, Tab } from "@material-ui/core";
import {
    getPraetorianClass,
    getPraetorianMaterialByClassId,
} from "../../api/praetorian";
import TabPanel from "components/common/TabPanel";

function materialProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const PraetorianMaterialPage = () => {
    const [addMaterial, setAddMaterial] = useState(false);
    const [material, setMaterial] = useState("");
    const [classId, setClassId] = useState("");
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        getPraetorianClass().then((res) => {
            const classId = res.data.data.filter((data) => data.termId === 3);
            setClassId(classId[0].id);
            classId &&
                getPraetorianMaterialByClassId(classId[0].id).then((res) => {
                    if (
                        typeof res.data.data != "object" ||
                        res.data.data.length === 0
                    )
                        return setMaterial(false);
                    setMaterial(res.data.data);
                });
        });
    }, [classId]);

    return (
        <>
            <Navbar title="Material" />
            <Wrapper>
                <div className="header">
                    <h1>Material</h1>
                    <button
                        className="add-material"
                        onClick={() => setAddMaterial(!addMaterial)}
                    >
                        Add Material
                    </button>
                    {addMaterial && (
                        <AddMaterial
                            closeModal={() => setAddMaterial(!addMaterial)}
                        />
                    )}
                </div>

                <Box sx={{ width: "100%" }}>
                    <Box sx={{ borderBottom: 1, marginBottom: "20px" }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            textColor="inherit"
                            TabIndicatorProps={{
                                style: {
                                    backgroundColor: "#31426E",
                                },
                            }}
                            aria-label="praetorian material"
                            centered
                        >
                            <Tab
                                label="UI/UX-A"
                                style={{
                                    fontSize: "16px",
                                    color: "#31426E",
                                    fontWeight: "600",
                                }}
                                {...materialProps(0)}
                            />
                            <Tab
                                label="UI/UX-B"
                                style={{
                                    fontSize: "16px",
                                    color: "#31426E",
                                    fontWeight: "600",
                                }}
                                {...materialProps(1)}
                            />
                            <Tab
                                label="UI/UX-C"
                                style={{
                                    fontSize: "16px",
                                    color: "#31426E",
                                    fontWeight: "600",
                                }}
                                {...materialProps(2)}
                            />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        {material ? (
                            material?.map((log, index) => {
                                return (
                                    <SessionBoxPraetorian
                                        materialId={log.id}
                                        session={log.session}
                                        topic={log.title}
                                        link={log.filePath}
                                        key={index}
                                    />
                                );
                            })
                        ) : (
                            <h4 className="no-materials">
                                You have not uploaded any material at the
                                moment.
                            </h4>
                        )}
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <h4 className="no-materials">
                            You have not uploaded any material at the moment.
                        </h4>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        {material ? (
                            material?.map((log, index) => {
                                return (
                                    <SessionBoxPraetorian
                                        materialId={log.id}
                                        session={log.session}
                                        topic={log.title}
                                        link={log.filePath}
                                        key={index}
                                    />
                                );
                            })
                        ) : (
                            <h4 className="no-materials">
                                You have not uploaded any material at the
                                moment.
                            </h4>
                        )}
                    </TabPanel>
                </Box>
            </Wrapper>
            <Footer />
        </>
    );
};

export default PraetorianMaterialPage;
