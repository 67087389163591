import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import RegisterClassForm from "components/pages/RegisterClassForm";
import { useHistory } from "react-router";
import { useEffect } from "react";
import { isTokenExist } from "utils/http-api";

const RegisterClass = () => {
  const history = useHistory();

  useEffect(() => {
    if (!isTokenExist()) {
      history.push("/login");
    }
  }, [history]);

  return (
    <>
      <Navbar title="Register" />
      <RegisterClassForm />
      <Footer />
    </>
  );
};

export default RegisterClass;
