import React from "react";
import {
  StyledCard,
  StyledCardContent,
  StyledCardHeader,
  StyledCardDescription,
} from "./styles";

const ClassNotesDetail = ({ note }) => {
  return (
    <StyledCard>
      <StyledCardHeader>
        <span>BNCC</span>
        <span>{note.timeCreated}</span>
      </StyledCardHeader>
      <StyledCardContent>
        <StyledCardDescription>{note.content}</StyledCardDescription>
      </StyledCardContent>
    </StyledCard>
  );
};

export default ClassNotesDetail;
