import React, { useEffect, useState } from "react";
import AttendanceLog from "components/pages/AttendanceLog/AttendanceLog";
import Footer from "../../components/common/Footer";
import Navbar from "../../components/common/Navbar/index";
import { Wrapper, Container } from "./styles";
import { getMemberAttendance } from "../../api/member";
import { getUserInfo } from "api/auth";

function AttendancePage() {
    const [memberAttendance, setMemberAttendance] = useState("");
    const [bnccId, setBnccId] = useState(null);

    useEffect(() => {
        getUserInfo().then((res) => {
            setBnccId(res.data.id);
            bnccId &&
                getMemberAttendance(bnccId).then((res) => {
                    if (
                        typeof res.data.data != "object" ||
                        res.data.data.length === 0
                    )
                        return setMemberAttendance(false);
                    setMemberAttendance(res.data.data);
                });
        });
    }, [bnccId]);

    return (
        <Wrapper>
            <Navbar title="Attendance Log" />
            <h1>Attendance Log</h1>
            <Container>
                {memberAttendance ? (
                    memberAttendance.map((log, i) => {
                        return (
                            <AttendanceLog
                                key={i}
                                session={i + 1}
                                date={log.date}
                                variants={log.status}
                            />
                        );
                    })
                ) : (
                    <h2>No attendance available</h2>
                )}
            </Container>
            <Footer />
        </Wrapper>
    );
}

export default AttendancePage;
