import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { isTokenExist } from "utils/http-api";

import Footer from "../../components/common/Footer";
import Navbar from "../../components/common/Navbar";
import LoginForm from "../../components/pages/Auth/LoginForm";

const LoginPage = () => {
  const history = useHistory();

  useEffect(() => {
    if (isTokenExist()) {
      history.push("/dashboard");
    }
  }, [history]);

  return (
    <>
      <Navbar title="Login" />
      <LoginForm />
      <Footer />
    </>
  );
};

export default LoginPage;
