import styled from "styled-components";
import { mainBlue } from "styles/color";

export const Container = styled.main`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    margin: 0 2rem;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

export const Text = styled.div`
  width: 35rem;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const Button = styled.button`
  cursor: pointer;
  border-radius: 6px;
  font-size: 0.8rem;
  font-weight: 500;
  margin-right: 1rem;
  color: #f7f7f7;
  background: ${mainBlue};
  padding: 0.8rem 1.5rem;
  border: none;
  font-size: 16px;
  font-family: "Montserrat";

  &:hover {
    background-color: #5a688b;
  }

  &:active {
    background-color: #253254;
  }
`;

export const Header = styled.h1`
  font-weight: 600;
  font-size: 3rem;
  margin: 0rem;
  font-family: "Montserrat";

  @media only screen and (max-width: 768px) {
    font-size: 2.5rem;
    padding-bottom: 1rem;
  }
`;

export const Paragraph = styled.p`
  margin-bottom: 3rem;
  line-height: 1.8rem;
`;

export const Image = styled.img`
  margin-right: 3rem;
  height: 25rem;

  @media only screen and (max-width: 768px) {
    margin: 0;
    width: 100%;
  }
`;
