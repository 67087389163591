import { PropsWithChildren, createContext, useContext, useMemo } from 'react'
import { UserContextState } from './types'
import { USER_STATE_DEFAULT } from './constants'
import useGetUser from './hooks/useGetUser';

export const UserContext = createContext<UserContextState>(USER_STATE_DEFAULT);


export const UserContextProvider = (props: PropsWithChildren<undefined>) => {
    const { children } = props;

    const { user } = useGetUser();

    const state: UserContextState = useMemo(() => ({
        user,
    }), [user])

    return <UserContext.Provider value={state}>{children}</UserContext.Provider>
}

export const useUser = () => useContext(UserContext);
