import styled from 'styled-components'
import { blue, white } from 'styles/color'
import { spacingM, spacingS, spacingXxl1 } from 'styles/spacing'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spacingXxl1} ${spacingM};
  margin: 3rem 0;
  background: ${white};
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
`

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: ${spacingS};
`

export const Card = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${blue};
  font-size: 16px;
  padding: 2rem 2.5rem;
  color: ${white};
  text-align: center;
  border-radius: 6px;
`

export const Title = styled.h4`
  font-size: 18px;
  margin: 0;
`

export const Content = styled.p`
  margin: 0;
  font-size: 16px;
`
