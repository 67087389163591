const FetchProject = (props) => {
  const curTime = new Date();

  const project = [
    {
      id: 3,
      name: 'Final Project',
      deadline: new Date('December 25, 2021 23:59:00'),
      location: './uploadsDummy/download.png',
    },
    {
      id: 2,
      name: 'Mid Project',
      deadline: new Date('March 05, 2021 23:59:00'),
      location: './uploadsDummy/download.png',
    },
    {
      id: 1,
      name: 'Assignment 1',
      deadline: new Date('March 08, 2021 23:59:00'),
      location: './uploadsDummy/download.png',
    },
  ]

  const upload = [
    {
      name: 'Ali_MidProject_81739272',
      file: 'File.zip (15kb)',
      location: '',
      time: new Date('March 08, 2021 23:59:00'),
      project_id: 2,
    },
    {
      name: 'Ali_MidProject_81739271',
      file: 'File.zip (15kb)',
      location: '',
      time: new Date('March 08, 2021 23:59:00'),
      project_id: 2,
    },
  ]

  const showDate = (date) => {
    let d = date.getDate()
    if (d < 10) d = '0' + d
    let m = date.toLocaleString('en-us', { month: 'long' })
    let y = date.getFullYear()
    let hour = date.getHours()
    if (hour < 10) hour = '0' + hour
    let min = date.getMinutes()
    if (hour < 10) min = '0' + min
    return d + ' ' + m + ' ' + y + ' (' + hour + ':' + min + ')'
  }

  const checkDL = (date, curr) => {
    return date < curr ? false : true
  }

  const getHistory = (val) => {
    let history = upload.filter((x) => x.project_id === val)
    props.onHistory(history)
  }

  const download = (x) => {
    if (checkDL(x.deadline, curTime)) {
      var a = document.createElement('a')
      a.href = x.location
      a.setAttribute('download', x.name)
      a.click()
    }
  }

  if (!Object.keys(project).length) {
    props.checkProject(false)
    return (
      <div className="no-project">
        <div>You have no project going on currently.</div>
      </div>
    )
  } else {
    props.checkProject(true)
    return project.map((d) => (
      <div key={d.id} className={'project ' + (checkDL(d.deadline, curTime) ? '' : 'off')}>
        <div>
          <div className="name">{d.name}</div>
          <div className="deadline">
            <span>Deadline: </span>
            <span>{showDate(d.deadline)} </span>
          </div>
          <button className="download-btn" onClick={download.bind(this, d)}>
            Download
          </button>
        </div>
        <div>
          <button
            className="upload-btn"
            onClick={
              checkDL(d.deadline, curTime) ? () => props.onUpload(d) : ''
            }
          >
            <img src="/images/member-project/upload_btn.svg" alt="" />
          </button>
          <button className="history-btn" onClick={getHistory.bind(this, d.id)}>
            <img src="/images/member-project/history_btn.svg" alt="" />
          </button>
        </div>
      </div>
    ))
  }
}

export default FetchProject
