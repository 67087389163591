import { getCourseName, getUserInfo } from "api/auth";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Image, WelcomeText, RoleText, Container } from "./styles";

import MaleAvatar from "images/avatar/male.svg";
import FemaleAvatar from "images/avatar/female.svg";
import { useContext } from "react";
import { UserContext } from "components/common/Context/UserContext";

const WelcomeAndProfile = (props) => {
  const [name, setName] = useState("");
  const [course, setCourse] = useState("");
  const [gender, setGender] = useState("");
  const { user } = useContext(UserContext)

  const findCourseName = (id, arr) => {
    const course = arr.find((data) => data.id === id);
    const courseName = course?.courseName;
    if (courseName === "Empty") {
      return "";
    }
    return course?.courseName;
  };

  const getAvatar = () => {
    if (gender === "male") {
      return MaleAvatar;
    } else if (gender === "female") {
      return FemaleAvatar;
    }
  };

  useEffect(() => {
    let courseData = [];
    getCourseName().then((res) => (courseData = res.data));
    getUserInfo().then((res) => {
      setCourse(findCourseName(res.data.courseId, courseData));
      setGender(res.data.gender);
      setName(res.data.name);
    });
  }, []);

  return (
    <Container>
      <Link to="/dashboard/profile">
        <Image src={getAvatar()} />
      </Link>
      <WelcomeText>Welcome, {name}</WelcomeText>
      <RoleText>{course} {user === 'prae' ? "Praetorian" : "Member"}</RoleText>
    </Container>
  );
};

export default WelcomeAndProfile;
