import styled from "styled-components";
import { blue, errorRed, white, mainBlack, secondaryBlack } from "styles/color";
import { spacingS, spacingXxl1, spacingXxl2, spacingXxl3, spacingXxl4 } from "styles/spacing";

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 24px !important;
  padding: 24px 24px;
  background-color: ${white};

  @media only screen and (max-width: 480px) {
    box-shadow: none;
    border: none;
    padding: 0;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Heading = styled.h1`
  font-weight: bolder;
  font-size: 2rem;
  text-align: center;
  color: ${mainBlack};
  font-weight: 600;
  font-family: "Montserrat";
  text-transform: uppercase;
  margin-bottom: 4px;
`;

export const SubHeading = styled.h2`
  font-weight: bold;
  font-size: 1.5rem;
  color: ${mainBlack};
  font-weight: 500;
  font-family: "Montserrat";
  margin: 0px;
`;

export const Divider = styled.hr`
  color: ${secondaryBlack};
  margin: 24px 0px;
`;

export const ContentWrapper = styled.div`
  width: 100$;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const Text = styled.p`
  text-align: center;
  color: ${mainBlack};
`;

export const Icon = styled.img`
  width: 16px;
  align-self: flex-start;
  &:hover {
    cursor: pointer;
  }
`;

export const Button = styled.button`
  cursor: pointer;
  margin-top: ${spacingXxl2};
  width: 100%;
  background-color: ${blue};
  color: ${white};
  padding: 15px 20px;
  border-radius: 6px;
  outline: none;
  border: none;
  font-family: "Montserrat";

  &:hover {
    background-color: #5a688b;
  }

  &:active {
    background-color: #253254;
  }
`;