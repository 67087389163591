import styled from "styled-components";
import { Card } from "semantic-ui-react";

export const StyledCard = styled(Card)`
  max-height: 100% !important;
  width: 100% !important;
`;

export const StyledCardHeader = styled(Card.Content)`
  display: flex;
  justify-content: space-between;
  background-color: #e0e0e0 !important;

  &:after {
    content: none !important;
  }
`;

export const StyledCardContent = styled(Card.Content)`
  &:after {
    content: none !important;
  }
`;

export const StyledCardDescription = styled(Card.Description)`
  margin: auto;
`;
