import React from "react";
import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import AttendanceLog from "../../components/pages/AttendanceLog/AttendanceLogs/index.jsx"

const AttendanceLogPage = () => {
    return (
        <>
            <Navbar title="Attendance Log" />
            <AttendanceLog />
            <Footer />
        </>
    );
};

export default AttendanceLogPage;
