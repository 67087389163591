import React, { useContext, useEffect, useState } from "react";
import { Menu, Heading, Image, Logout, Wrapper } from './style'
import BackIcon from 'images/back.svg'
import { useHistory } from 'react-router'
import { isTokenExist } from 'utils/http-api'
import { UserContext } from "components/common/Context/UserContext";
import { Link } from "react-router-dom";
import { Title } from "../CardList/style";
import LogoutConfirmation from "../LogoutConfirmation";

const Navbar = ({ title }) => {
  const history = useHistory();
  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    setUser(localStorage.getItem('role'));
  }, [user, setUser]);


  const [logoutConfirm, setLogoutConfirm] = useState(false);
  const [logoutSuccess, setLogoutSuccess] = useState(false);

  const openLogoutConfirmation = () => {
    setLogoutConfirm(true)
    console.log(logoutConfirm)
  }
  const closeLogoutConfirmation = () => {
    setLogoutConfirm(false)
  }
  const openLogoutSuccess = () => {
    setLogoutSuccess(true)
    console.log(logoutSuccess)
  }

  
  function checkRole(user) {
    if (user === "memb") {
      return <>
        <Link to="/dashboard">Home</Link>
        {/* <Link to="/member/material">Material</Link> */}
        <Link to="/member/attendance">Attendance Log</Link>
        {/* <Link to="/member/project">Project</Link> */}
      </>
    }
    else if (user === "prae") {
      return <>
        <Link to="/dashboard">Home</Link>
        <Link to="/praetorian/class">Classes</Link>
        <Link to="/praetorian/attendance">Make Attendance</Link>
        <Link to="/praetorian/attendance/log">Attendance Log</Link>
        {/* <Link to="/praetorian/material">Material</Link> */}
        <Link to="/praetorian/project">Project</Link>
      </>
    }
    else {
      return <>
        <Image src={BackIcon} alt="Back Icon" onClick={() => history.goBack()} />
        <Heading>{title}</Heading>
      </>
    }
  }

  return <>
    <Wrapper>
      {checkRole(user)}
      {isTokenExist() && <Logout onClick={() => {
        openLogoutConfirmation()
      }} />}
    </Wrapper>
    <LogoutConfirmation localStorage = {localStorage} history = {history}
      isOpen = {logoutConfirm} onClose = {closeLogoutConfirmation}
      isConfirm = {logoutSuccess} onConfirm = {openLogoutSuccess}/>
  </>
}

export default Navbar
