import styled from 'styled-components'

export const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;
    padding: 0 1rem;

    @media (max-width: 768px) {
        flex-direction: column;
    }

    .green{
        color: green;
    }

    .red{
        color: red;
    }
`;