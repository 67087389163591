import styled from 'styled-components'
import { blue, white } from 'styles/color'

export const Card = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${blue};
  font-size: 16px;
  padding: 2rem 2.5rem;
  color: ${white};
  text-align: center;
  border-radius: 6px;
`

export const Title = styled.h4`
  font-size: 18px;
  margin: 0;
`

export const Content = styled.p`
  margin: 0;
  font-size: 16px;
`
