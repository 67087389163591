import React from 'react'

import Profile from '../../components/pages/Profile'
import Footer from '../../components/common/Footer'
import Navbar from 'components/common/Navbar'

const ProfilePages = () => {
  return (
    <>
      <Navbar />
      <Profile />
      <Footer />
    </>
  )
}

export default ProfilePages
