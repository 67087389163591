import { Card, Content, Title } from './style'

const ContentCard = ({ type, name, day, beginTime, endTime, classId, modal = false, setClassId = false, setSchedule = false }) => {
 
  function validateClassId(id) {
    if (id.startsWith("be") ||
      id.startsWith("fe") ||
      id.startsWith("java") ||
      id.startsWith("mobile") ||
      id.startsWith("uiux")) {
      return true;
    }
  }

  return (
    <Card onClick={() => {
      if (classId && validateClassId(classId)) {
        modal(true);
        setClassId && setClassId(classId);
        setSchedule && setSchedule("Every" + " " + day + " " + beginTime.slice(0, 5) + " - " + endTime.slice(0, 5));
      }
    }}>
      {type !== 'praetorian' && <Title>{name}</Title>}
      <Content>
        {type === 'praetorian'
          ? `${name}`
          : `Every ${day}, ${beginTime.slice(0, 5)} - ${endTime.slice(0, 5)} `}
      </Content>
    </Card>
  )
}

export default ContentCard
