import api from ".";

import config from "../config";
import { getAccessToken } from "../utils/http-api";

const accessToken = getAccessToken();
const { projects } = config.urls.member;

export const getPraetorianClass = async () => {
    return api.get(`api/praetorian/class`, {
        headers: {
            Authorization: accessToken,
        },
    });
};

export const uploadPraetorianMaterial = async (
    title,
    filePath,
    session,
    classId
) => {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("filePath", filePath);
    formData.append("session", session);
    formData.append("classId", classId);
    return api.post(`api/praetorian/class/materials`, formData, {
        headers: {
            Authorization: accessToken,
            "content-type": "multipart/form-data",
        },
    });
};

export const updatePraetorianMaterial = async (
    id,
    title,
    filePath,
    session,
    classId
) => {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("filePath", filePath);
    formData.append("session", session);
    formData.append("classId", classId);

    return api.post(`api/praetorian/class/materials/${id}`, formData, {
        headers: {
            Authorization: accessToken,
            "content-type": "multipart/form-data",
        },
    });
};

export const deletePraetorianMaterial = async (id) => {
    return api.delete(`api/praetorian/class/materials/${id}`, {
        headers: {
            Authorization: accessToken,
        },
    });
};

export const getPraetorianMaterialByClassId = async (classId) => {
    return api.get(`api/praetorian/class/materials/${classId}`, {
        headers: {
            Authorization: accessToken,
        },
    });
};

export const getClassMembers = async (classId) => {
    return api.get(`api/praetorian/class/${classId}/members`, {
        headers: {
            Authorization: accessToken,
        },
    });
};

export const createAttendanceClass = async (
    date,
    description,
    image,
    classId
) => {
    const formData = new FormData();
    formData.append("date", date);
    formData.append("description", description);
    formData.append("image", image);
    formData.append("classId", classId);
    return api.post(`api/praetorian/attendance`, formData, {
        headers: {
            Authorization: accessToken,
        },
    });
};

export const createUserAttendance = async (attendanceId, userId, status) => {
    return api.post(
        `api/praetorian/user-attendance`,
        { attendanceId, userId, status },
        {
            headers: {
                Authorization: accessToken,
            },
        }
    );
};

export const getAttendaceByClassId = async (classId) => {
  return api.get(`api/praetorian/attendance/class/${classId}`, {
    headers: {
      Authorization: accessToken,
    },
  });
}

export const getMemberProjects = async () => {
  return api.get(projects.getProjects, {
    headers: {
      Authorization: accessToken,
    },
  });
}
