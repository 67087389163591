import { getUserInfo } from "api/auth";
import { getAvailableClass, getClass, registerRegularClass } from "api/member";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Card, Form } from "semantic-ui-react";
import { courseResponseToDropdownTransformer } from "utils/transformer";
import WelcomeAndProfile from "../../common/WelcomeAndProfile";
import {
  BlueText,
  BoldText,
  CardDescription,
  ConfirmButton,
  CancelButton,
  ModalHeader,
  StyledButton,
  StyledCard,
  StyledContainer,
  StyledDropdown,
  StyledHeader,
  StyledModal,
  StyledModalActions,
  StyledModalContent,
  CloseIcon,
} from "./styles";

const RegisterClassForm = () => {
  const [scheduleOptions, setScheduleOptions] = useState([]);
  const [selectedScheduleValue, setSelectedScheduleValue] = useState("");
  const [selectedScheduleText, setSelectedScheduleText] = useState("");
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const changeSchedule = (e, data) => {
    const selectedScheduleItem = data.options.find(
      (option) => option.value === data.value
    );
    setSelectedScheduleValue(data.value);
    setSelectedScheduleText(selectedScheduleItem.text);
  };

  const onSubmitClick = () => {
    getUserInfo().then((res) => {
      let bnccId = res.data.id;
      registerRegularClass(bnccId, selectedScheduleValue).then((res) => {
        if (res.status === 200) {
          history.push("/dashboard");
        } else {
          history.go(0);
        }
      });
    });
  };

  useEffect(() => {
    getUserInfo().then((res) => {
      let courseId = res.data.courseId;
      if (courseId === "empty") {
        history.push("/register-trial");
      }
      getClass().then((res) => {
        let data;
        if (res.data.data) {
          data = res.data?.data[0];
        }
        if (data) {
          history.push("/dashboard");
        }
      });
      getAvailableClass(courseId).then((res) => {
        let transformedRes;
        if (res.data.data) {
          transformedRes = courseResponseToDropdownTransformer(res.data);
        }
        setScheduleOptions(transformedRes);
      });
    });
  }, [history]);

  return (
    <StyledContainer>
      <WelcomeAndProfile img="https://react.semantic-ui.com/images/wireframe/square-image.png" />
      <StyledCard>
        <Card.Content>
          <StyledHeader>Welcome to Leap</StyledHeader>
          <CardDescription>
            Register your schedule now so we can arrange a class for you as soon
            as possible
          </CardDescription>
        </Card.Content>
        <Form>
          <Form.Field>
            <p>Class Schedule</p>
            <StyledDropdown
              placeholder="Select Schedule"
              selection
              options={scheduleOptions}
              onChange={(e, data) => changeSchedule(e, data)}
            />
          </Form.Field>
          <StyledModal
            open={open}
            size="tiny"
            trigger={
              <StyledButton
                primary
                disabled={selectedScheduleValue === "" ? true : false}
                id="submit"
              >
                Register Schedule
              </StyledButton>
            }
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
          >
            <CloseIcon onClick={() => setOpen(false)}>×</CloseIcon>
            <ModalHeader>Submission Confirmation</ModalHeader>
            <StyledModalContent>
              Are you sure to choose <BoldText>{selectedScheduleText}</BoldText>
              ?{" "}
              <BlueText>
                You won’t be able to change your schedule once it’s submitted.
              </BlueText>
            </StyledModalContent>
            <StyledModalActions>
              <CancelButton onClick={() => setOpen(false)}>Cancel</CancelButton>
              <ConfirmButton onClick={onSubmitClick}>Confirm</ConfirmButton>
            </StyledModalActions>
          </StyledModal>
        </Form>
      </StyledCard>
    </StyledContainer>
  );
};

export default RegisterClassForm;
