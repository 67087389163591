import styled from "styled-components";

export const Wrapper = styled.div`
    h1 {
        text-align: center;
    }

    h2 {
        margin-top: 1rem;
    }

    .absent {
        background: #D97441;
        cursor: default;
    }

    .present {
        background: #31426E;
        cursor: default;
    }
`

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`