import { useEffect, useState } from "react";
import courses from "constants/courses";
import CardList from "components/common/CardList";
import { Section, Wrapper } from "./style";
import { getPraetorianClass } from "api/praetorian";
import { getUserInfo } from "api/auth";

const ClassList = ({ page }) => {
  const [data, setData] = useState();
  const [course, setCourse] = useState();
  const [searchTerm, setSearchTerm] = useState(null);

  useEffect(() => {
    getPraetorianClass().then(res => {
      setData(res.data.data.filter((e) => e.termId === 3));
    });
    getUserInfo().then(res => {
      setCourse(res.data.courseId);
    });
  }, [course]);
 
  const renderCard = courses.map((e, idx) => {
    return e.id === course ? <CardList key={idx} course={e} data={data} type={page} /> : false;
  });

  const renderContent = () => {
    if (!searchTerm) return renderCard;
  };

  return (
    <Section>
      <Wrapper>{data && renderContent()}</Wrapper>
    </Section>
  );
};

export default ClassList;
