import { useState } from "react";

import { Header } from 'semantic-ui-react'
import ContentCard from '../ContentCard'
import { Container, Wrapper } from './style'
import ClassDetailModal from "components/common/Modal/ClassDetailModal";

const CardList = ({ course, data, type }) => {
  const [modal, setModal] = useState(false);
  const [classId, setClassId] = useState(0);
  const [schedule, setSchedule] = useState(" ");

  const renderData = data
    .filter((datum) => datum.courseId === course.id)
    .map((datum, idx) => {
      return <ContentCard setSchedule={setSchedule} setClassId={setClassId} modal={setModal} classId={datum.id} key={idx} {...datum} type={type} />
    })
  return (
    <Container>
      <ClassDetailModal schedule={schedule} classId={classId} open={modal} close={() => setModal(false)} />
      <Header as="h2">{course.name}</Header>
      <Wrapper>{data && renderData}</Wrapper>
    </Container>
  )
}

export default CardList
