import { Form } from "formik";
import { Input } from "formik-semantic-ui-react";
import styled from "styled-components";
import { blue, errorRed, white, mainBlack } from "../../../../styles/color";
import { spacingS, spacingXxl1, spacingXxl4 } from "../../../../styles/spacing";

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 24px !important;
  padding: 48px 48px 64px 48px;
  background-color: ${white};

  @media only screen and (max-width: 480px) {
    box-shadow: none;
    border: none;
    padding: 0;
  }
`;

export const Heading = styled.h1`
  font-weight: bolder;
  font-size: 2rem;
  text-align: center;
  color: ${blue};
  font-weight: 600;
  font-family: "Montserrat";
`;

export const Text = styled.p`
  text-align: center;
  color: ${mainBlack};
`;

export const FormStyled = styled(Form)`
  display: flex;
  flex-direction: column;
  color: ${mainBlack};
  
  > div {
    display: flex;
    flex-direction: column;
  }

  label {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
`;

export const StyledInput = styled(Input)`
  min-width: 300px !important;
  
  > input {
    padding-right: 0px !important;
  }
`;

export const Icon = styled.img`
  margin-left: -8%;
  width: 16px;
  cursor: pointer;
  z-index: 2;
`;

export const Image = styled.img`
  float: right;
  width: 16px;
  cursor: pointer;
`

export const Button = styled.button`
  cursor: pointer;
  margin-top: ${spacingXxl4};
  width: 100%;
  background-color: ${blue};
  color: ${white};
  padding: 10px 20px;
  border-radius: 6px;
  outline: none;
  border: none;
  font-family: "Montserrat";

  &:hover {
    background-color: #5a688b;
  }

  &:active {
    background-color: #253254;
  }
`;

export const Error = styled.div`
  color: ${errorRed};
`;
