import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 30%;
    margin: 3rem auto;
    padding: 0 3rem;
    border-radius: 1rem;
    box-shadow: 0px 2px 10px 0px #00000040;

    *{
        margin: 0;
        padding: 0;
    }

    h1{
        text-align: center;
        padding: 3rem;
        padding-bottom: 2rem;
    }

    input{
        margin-bottom: 1rem;
    }

    button{
        text-align: right;
        width: 100%;
        color: #31426E;
        padding: 1rem;
    }

    p{
        margin-bottom: .5rem;
    }

    .buttons{
        display: flex;
    }

    .form-content{
        border: 1px solid black;
        border-radius: .5rem;
        margin-bottom: 1rem;
    }

    .member-list{
        height: 23rem;
        overflow: scroll;
        overflow-x: hidden;
    }

    .member-list-item{
        display: flex;
        margin: 1rem;
        gap: 1rem;
    }

    .btn-enabled{
        background: #31426E;
        color: white;
        text-align: center;
        padding: .5rem 0;
        border-radius: .5rem;
        margin-bottom: 3rem;
    }

    .btn-disabled{
        background: #828282;
        color: white;
        text-align: center;
        padding: .5rem 0;
        border-radius: .5rem;
        margin-bottom: 3rem;
    }
`