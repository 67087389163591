import React, { useState, useEffect } from 'react';

import Footer from '../../components/common/Footer';
import Navbar from '../../components/common/Navbar/index'
import ClassList from '../../components/pages/ClassList';


const ClassListPage = () => {

    return (
        <>
            <Navbar title="Class List" />
            <ClassList/>
            <Footer />
        </>
    );
}

export default ClassListPage;