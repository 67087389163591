import styled from 'styled-components'
import { white } from 'styles/color'
import { spacingL, spacingM } from 'styles/spacing'

export const Section = styled.div`
  padding: 5rem;

  h1 {
    text-align: center;
  }
`

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: ${spacingL};
  background: ${white};
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: ${spacingM};
`
