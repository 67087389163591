import { Wrapper, Head, Content, Name, Text, Description, Image } from './style'

const Accordian = ({
  active,
  setActive,
  id,
  name,
  activeClass,
  description,
}) => {
  return (
    <Wrapper>
      <Head>
        <Content>
          <Name>{name}</Name>
          <Text>{activeClass} class(es)</Text>
        </Content>
        <Image
          active={active === id}
          onClick={() => setActive(active === id ? '' : id)}
          src="/images/icon/arrow.svg"
          alt="images"
        />
      </Head>
      <Description active={active === id}>
        <hr />
        {description}
      </Description>
    </Wrapper>
  )
}

export default Accordian
