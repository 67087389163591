import { Card, Header } from "semantic-ui-react";
import styled from "styled-components";

export const StyledCard = styled(Card)`
  max-height: 100% !important;
  width: 100% !important;
`;

export const StyledCardContent = styled(Card.Content)`
  padding: 2rem !important;
`;

export const StyledCardAction = styled(Card.Content)`
  padding: 1.5rem 3rem !important;
`;

export const StyledHeader = styled(Header)`
  font-family: "Montserrat" !important;
  color: #31426e !important;
  padding-bottom: 1.5rem !important;
  font-weight: 600 !important;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
`;

export const ChartContainer = styled.div`
  grid-area: 1 / 1 / 2 / 5;
  padding-right: 2rem;
  margin: auto;
`;

export const StatsContainer = styled.div`
  grid-area: 1 / 5 / 2 / 8;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: auto;
`;
