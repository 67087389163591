import axios from "axios";

console.log(process.env);

const api = axios.create({
  baseURL: `https://${process.env.REACT_APP_API_URL}`,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  },
});

export default api;
