export const spacingS = '8px'
export const spacingM = '16px'
export const spacingL = '20px'
export const spacingXl = '24px'
export const spacingXxl1 = '32px'
export const spacingXxl2 = '36px'
export const spacingXxl3 = '40px'
export const spacingXxl4 = '48px'
export const spacingXxl5 = '64px'
export const spacingXxl6 = '128px'
