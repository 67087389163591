import styled from "styled-components";
import { white } from "styles/color";

export const StyledFooter = styled.footer`
  position: sticky;
  width: 100%;
  bottom: 0;
  left: 0;
  text-align: center;
  padding: 3rem;
  background: ${white};
`;
