import styled from "styled-components";
import { Button, Card, Header } from "semantic-ui-react";

export const Container = styled.div`
  color: $white;
`;

export const StyledButton = styled(Button)`
  background: transparent !important;
  border: 1px solid #f7f7f7 !important;
  color: #f7f7f7 !important;
  font-family: "Montserrat" !important;
  font-weight: 400 !important;
  margin-top: 1rem !important;

  &:hover {
    color: #5ed8f8 !important;
    border: 1px solid #5ed8f8 !important;
  }
`;

export const StyledInvertedButton = styled(Button)`
  background: #f7f7f7 !important;
  border: 1px solid #f7f7f7 !important;
  color: #31426e !important;
  font-family: "Montserrat" !important;
  font-weight: 400 !important;
  margin-top: 1rem !important;

  &:hover {
    color: #f7f7f7 !important;
    border: 1px solid #5ed8f8 !important;
    background-color: #5ed8f8 !important;
  }
`;

export const StyledHeader = styled(Header)`
  margin: 1rem 0rem 0.5rem;
  font-family: "Montserrat" !important;
  font-weight: 700 !important;
`;

export const StyledCard = styled(Card)`
  background: #31426e !important;
  border-radius: 16px !important;
  padding: 0rem 1rem !important;
  margin-top: 4rem !important;
  color: #f7f7f7;
`;
