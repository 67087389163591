import { getMemberList } from "api/admin";
import ContentCard from "components/common/ContentCard";
import { useEffect, useState } from "react";
import { Header, Input } from "semantic-ui-react";
import { Section, Wrapper } from "./style";

const MemberList = () => {
  const [members, setMembers] = useState();
  const [searchTerm, setSearchTerm] = useState(null);

  useEffect(() => {
    const getMember = async () => {
      const response = await getMemberList();
    /*   console.log(response.data); */
      setMembers(response.data.data);
    };
    getMember();
  }, []);

  const onSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const renderMember = () => {
/*     console.log(members); */
    if (!searchTerm)
      return members.map((member, idx) => {
        return <ContentCard {...member} key={idx} type="praetorian" />;
      });

    return members
      .filter((member) => member.name.toLowerCase().includes(searchTerm))
      .map((member, idx) => {
        return <ContentCard {...member} key={idx} type="praetorian" />;
      });
  };

  return (
    <Section>
      <Header as="h1">Member List</Header>
      <Input
        icon="search"
        placeholder="Search..."
        id="input"
        onChange={(e) => onSearch(e)}
      />
      <Wrapper>{members && renderMember()}</Wrapper>
    </Section>
  );
};

export default MemberList;
