import styled from 'styled-components';
import { blue, white, mainBlack } from 'styles/color';
import { spacingL, spacingM } from 'styles/spacing';

export const Wrapper = styled.nav`
    width: 798px;
    padding: 3px;
    margin: auto;
    box-shadow: 0px 2px 10px 0px #00000040;
    border-radius: 6px;
    max-height: 688px;
`

export const Course = styled.button`
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: -0.015em;
    background-color: ${blue};
    opacity: ${({isSelected}) => (isSelected? 1 : 0.4)};
    color: ${white};
    padding: ${spacingM} ${spacingL};
    width: 260px;
    margin: 1px 1.5px;
    border-radius: ${({leftMost,rightMost}) => (leftMost? '3px 0px 0px 0px' : rightMost? '0px 3px 0px 0px' : '0px')};
`

export const Table = styled.table`
    width: 793px;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    margin: auto;
`
export const GridHeading = styled.th`
    border: 1px solid ${white};
    background-color: #4C639F;
    color: ${white};
    text-align: center;
    padding: ${spacingM} ${spacingL};
    width: ${({width}) => (width)};
    height: 54px;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.6px;
`

export const GridCell = styled.td`
    border: 1px solid ${white};
    background-color: ${({row}) => (row%2==0? '#CAD5F0' : '#E8EEFF')};
    color: ${mainBlack};
    text-align: center;
    padding: 12px;
    width: ${({width}) => (width)};
    height: 44px;
    border-radius: ${({isLast, isLeftMost}) => (!isLast? '0px' : isLeftMost? '0px 0px 0px 3px' : '0px')};
`

export const GridButton = styled.td`
    border: 1px solid ${white};
    background-color: ${({row}) => (row%2==0? '#CAD5F0' : '#E8EEFF')};
    justify-content: center;
    padding: 12px 8px;
    width: ${({width}) => (width)};
    height: 44px;
    border-radius: ${({isLast, isRightMost}) => (!isLast? '0px' : isRightMost? '0px 0px 3px 0px' : '0px')};
`

export const ProjectLink = styled.a`
    border: 1px solid #4C639F;
    color: #4C639F;
    opacity: ${({isAvailable}) => (isAvailable? 1 : 0.4)};
    border-radius: 4px;
    padding: 6px 10px;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    cursor: ${({isAvailable}) => (isAvailable? 'pointer' : 'default')};
    pointer-events: ${({isAvailable}) => (isAvailable? 'auto' : 'none')};
`