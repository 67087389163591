import React from "react";
import { Wrapper } from "./styles";

const MemberAttendance = ({id, name, status}) => {
  return (
    <Wrapper>
      <p>{id} | {name}</p>
      <p className={status==='Present' ? 'green' : 'red'}>{status}</p>
    </Wrapper>
  );
};

export default MemberAttendance;
