import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20rem;
    .header {
        display: flex;
        justify-content: space-between;
        padding: 50px 25px 25px 25px;
    }
    .contents {
        width: 100%;
        padding: 25px;
    }
    .no-materials {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60vh;
    }
    button:hover {
        filter: brightness(90%);
        cursor: pointer;
    }
`;
