import { getCourseList } from 'api/admin'
import Accordian from 'components/common/Accordian'
import { useEffect } from 'react'
import { useState } from 'react'
import { Header } from 'semantic-ui-react'
import { Section, Card } from './style'

const CourseList = () => {
  const [active, setActive] = useState()
  const [courses, setCourses] = useState([])

  useEffect(() => {
    const getCourse = async () => {
      const response = await getCourseList()
      setCourses(response.data.data)
    }
    getCourse()
  }, [])

  return (
    <Section>
      <Header as="h1">Course</Header>
      <Card>
        {courses &&
          courses.map((course) => {
            return (
              <Accordian
                active={active}
                setActive={setActive}
                key={course.id}
                {...course}
              />
            )
          })}
      </Card>
    </Section>
  )
}

export default CourseList
