import ReactDOM from 'react-dom'
import App from './App'
import GlobalStyle from './styles'

import './styles/main.scss'

ReactDOM.render(
  <>
    <App />
    <GlobalStyle />
  </>,
  document.querySelector('#root')
)
