import courses from "constants/courses";
import { useState } from "react";
import Image from "images/trial/trial.png";
import {
  Button,
  Card,
  Container,
  Content,
  Description,
  Header,
  ClassWrapper,
  ImageWrapper,
  Title,
  Wrapper,
} from "./style";
import { updateMemberCourse } from "api/member";
import { useHistory } from "react-router";
import { getUserInfo } from "api/auth";
import { Checkbox } from "semantic-ui-react";

const RegisterTrial = () => {
  const [course, setCourse] = useState(courses[0]);
  const [checked, setChecked] = useState(false);
  const history = useHistory();

  const onSubmitClick = () => {
    checked &&
      getUserInfo().then((res) => {
        let bnccId = res.data.id;
        updateMemberCourse(bnccId, course.id).then((res) => {
          if (res.status === 200) {
            // history.push("/register-class");
            history.push("/dashboard");
          }
        });
      });
  };

  const renderClass = courses.map((data) => {
    return (
      <Card key={data.id} onClick={() => setCourse(data)} active={data.name === course.name}>
        {data.name}
      </Card>
    );
  });

  // useEffect(() => {
  //   getUserInfo().then((res) => {
  //     if (res.data.courseId !== "empty") {
  //       history.push("/dashboard");
  //     }
  //   });
  // }, [history]);

  return (
    <Container>
      <Header>Choose Your Desired Course</Header>
      <Description>Pick one from the following courses</Description>
      <ClassWrapper>{renderClass}</ClassWrapper>
      <Wrapper>
        <ImageWrapper>
          <img src={course.image} alt="" />
        </ImageWrapper>
        <Content>
          <Title>{course.name}</Title>
          <Description>{course.desciption}</Description>
          <Checkbox
            label="Saya sudah yakin dan setuju atas pilihan saya."
            onChange={() => setChecked(!checked)}
            checked={checked}
          />
          <Button checked={checked} onClick={() => onSubmitClick()}>
            Confirm
          </Button>
        </Content>
      </Wrapper>
    </Container>
  );
};

export default RegisterTrial;
