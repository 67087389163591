import React from "react";
import { useState } from "react";
import {
    AttendanceLogRows,
    Wrapper,
    SelectClassButtons,
    SelectClassButton,
} from "./styles";
import ClassInfo from "../ClassInfo";
import AttendanceLog from "../AttendanceLog";
import { useEffect } from "react";
import { getAttendaceByClassId, getPraetorianClass } from "api/praetorian";
import { getUserInfo } from "api/auth";
import AttendanceModal from "../AttendanceModal";

const AttendanceLogs = () => {
    const [classes, setClasses] = useState([]);
    const [praetoName, setPraetoName] = useState("");
    const [praetoTopic, setPraetoTopic] = useState("");
    const [currentId, setCurrentId] = useState("");
    const [currentSchedule, setCurrentSchedule] = useState({
        day: "",
        time: "",
    });
    const [attendance, setAttendance] = useState([]);
    const [attendanceDates, setAttendanceDates] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalDate, setModalDate] = useState("");
    const [attendanceInfo, setAttendanceInfo] = useState([]);

    const getClassInfo = (date = false) => {
        setShowModal(true);

        setModalDate(date);
        setAttendanceInfo(attendance[date]);
    };

    useEffect(() => {
        getUserInfo().then((res) => {
            setPraetoName(res.data.name.split(" ")[0]);
            setPraetoTopic(res.data.courseName);
        });
    }, []);

    useEffect(() => {
        getPraetorianClass().then((res) => {
            setClasses(
                res?.data?.data
                    .filter((el) => el.termId !== 1)
                    .map((el) => el.id)
            );
        });
    }, []);

    useEffect(() => {
        setCurrentId(classes[0]);
    }, [classes]);

    useEffect(() => {
        getAttendaceByClassId(currentId).then((res) => {
            setAttendance(res?.data?.data?.attendances);
            setAttendanceDates(Object.keys(res.data.data.attendances));
            setCurrentSchedule({
                day: res?.data?.data?.day,
                time:
                    res?.data?.data?.beginTime + "-" + res?.data?.data?.endTime,
            });
        });
    }, [currentId]);

    const generateAttendances = () => {
        return attendanceDates.map((date, index) => {
            return (
                <AttendanceLog
                    session={index + 1}
                    key={index}
                    date={date}
                    attendances={attendanceInfo.filter(
                        (attendance) => attendance.date === date
                    )}
                    getClassInfo={getClassInfo}
                />
            );
        });
    };

    return (
        <Wrapper>
            {showModal && (
                <AttendanceModal
                    date={modalDate}
                    praetoName={praetoName}
                    classId={currentId}
                    topic={praetoTopic}
                    schedule={`Every ${currentSchedule.day}, ${currentSchedule.time}`}
                    setShowModal={setShowModal}
                    attendanceInfo={attendanceInfo}
                />
            )}
            <ClassInfo
                name={praetoName}
                subject={praetoTopic}
                schedule={`Every ${currentSchedule.day}, ${currentSchedule.time}`}
            />
            <SelectClassButtons>
                {classes?.map((id) => (
                    <SelectClassButton
                        key={id}
                        onClick={() => setCurrentId(id)}
                        active={currentId === id}
                    >
                        {id.toUpperCase()}
                    </SelectClassButton>
                ))}
            </SelectClassButtons>
            <AttendanceLogRows>
                {attendance?.attendance?.length < 1 ? (
                    <h1>No Attendance Available</h1>
                ) : (
                    generateAttendances()
                )}
            </AttendanceLogRows>
        </Wrapper>
    );
};

export default AttendanceLogs;
