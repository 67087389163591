import styled from 'styled-components'
import { blue } from 'styles/color'

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;
`

export const Header = styled.h1`
  color: ${blue};
  font-size: 108px;
  margin: 0;
`

export const Description = styled.p`
  font-size: 64px;
  font-weight: 500;
`
