import React from "react";
import { Wrapper } from "./styles";

const AttendanceLog = ({ session, date, variants, getClassInfo }) => {
  return (
    <Wrapper className={variants} onClick={() => {getClassInfo && getClassInfo(date)}}>
      <p>Session {session}</p>
      <p>{date}</p>
    </Wrapper>
  );
};

export default AttendanceLog;