import api from '.'
import { getAccessToken } from '../utils/http-api'

const accessToken = getAccessToken()

export const getAttendance = async () => {
  return await api.get(`/api/admin/attendance`, {
    headers: {
      Authorization: accessToken,
    },
  })
}

export const getClassById = async (id) => {
  return await api.get(`api/admin/class/${id}`, {
    headers: {
      Authorization: accessToken,
    },
  })
}

export const getClassList = async () => {
  return await api.get(`api/admin/class`, {
    headers: {
      Authorization: accessToken,
    },
  })
}

export const getPraetorianList = async () => {
  return await api.get(`api/admin/praetorian`, {
    headers: {
      Authorization: accessToken,
    },
  })
}

export const getCourseList = async () => {
  return await api.get(`api/admin/course`, {
    headers: {
      Authorization: accessToken,
    },
  })
}

export const getMemberList = async () => {
  return await api.get(`api/admin/member`, {
    headers: {
      Authorization: accessToken,
    },
  })
}
