export const SESSION_VALUE = [
    {
        key: 1,
        value: 1,
        text: "Session 1",
    },
    {
        key: 2,
        value: 2,
        text: "Session 2",
    },
    {
        key: 3,
        value: 3,
        text: "Session 3",
    },
    {
        key: 4,
        value: 4,
        text: "Session 4",
    },
    {
        key: 5,
        value: 5,
        text: "Session 5",
    },
    {
        key: 6,
        value: 6,
        text: "Session 6",
    },
    {
        key: 7,
        value: 7,
        text: "Session 7",
    },
    {
        key: 8,
        value: 8,
        text: "Session 8",
    },
    {
        key: 9,
        value: 9,
        text: "Session 9",
    },
    {
        key: 10,
        value: 10,
        text: "Session 10",
    },
    {
        key: 11,
        value: 11,
        text: "Session 11",
    },
    {
        key: 12,
        value: 12,
        text: "Session 12",
    },
    {
        key: 13,
        value: 13,
        text: "Session 13",
    },
];