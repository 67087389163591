import React, { useState } from "react";
import { Wrapper } from "./styles";
import UpdateMaterial from "../MaterialModal/UpdateMaterial";
import DeleteMaterial from "../MaterialModal/DeleteMaterial";
import editIcon from "../../../../assets/images/icon/edit.svg";
import deleteIcon from "../../../../assets/images/icon/delete.svg";

const SessionBoxPraetorian = ({ materialId, session, topic, link }) => {
    const [updateMaterial, setUpdateMaterial] = useState(false);
    const [deleteMaterial, setDeleteMaterial] = useState(false);

    return (
        <Wrapper>
            <div>
                <h2>Session {session}</h2>
                <div>
                    <p>{topic}</p>
                </div>
            </div>
            <div className="button-flex">
                <button onClick={() => setUpdateMaterial(!updateMaterial)}>
                    <img src={editIcon} alt="" />
                </button>
                {updateMaterial && (
                    <UpdateMaterial
                        closeModal={() => setUpdateMaterial(!updateMaterial)}
                        materialId={materialId}
                        latestSession={session}
                        latestTitle={topic}
                        latestLink={link}
                    />
                )}
                <button onClick={() => setDeleteMaterial(!deleteMaterial)}>
                    <img src={deleteIcon} alt="" />
                </button>
                {deleteMaterial && (
                    <DeleteMaterial
                        closeModal={() => setDeleteMaterial(!deleteMaterial)}
                        materialId={materialId}
                    />
                )}
            </div>
        </Wrapper>
    );
};

export default SessionBoxPraetorian;
