import { getUserInfo } from "api/auth";
import { getClass } from "api/member";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";

import {
  findCountdownDuration,
  findTheNextWeekday,
} from "../../../../utils/time";
import {
  Container,
  StyledButton,
  StyledCard,
  StyledHeader,
  StyledInvertedButton,
} from "./styles";

const NextClassReminder = () => {
  const [classDate, setClassDate] = useState("");
  const [classCountdown, setClassCountdown] = useState("");
  const [haveCourse, setHaveCourse] = useState(true);
  const [haveClass, setHaveClass] = useState(false);
  const history = useHistory();

  useEffect(() => {
    getUserInfo().then((res) => {
      if (res.data.courseId === "empty") {
        setHaveCourse(false);
      } else {
        setHaveCourse(true);
      }
    });
  }, []);

  const handleButtonClick = (url) => {
    window.open(url, "_blank").focus();
  };

  useEffect(() => {
    getUserInfo().then((res) => {
      if (res.data.roleId === "admin") {
        history.push("/admin");
      }
      getClass().then((res) => {
        let data;
        if (res.data.data) {
          data = res.data.data[0];
        }
        if (!data) {
          // history.push("/register-class");
          setClassCountdown(`Coming Soon!`);
          setClassDate(`Please wait for further announcement.`);
        } else {
          setHaveClass(true);
          const date = findTheNextWeekday(data?.day);
          const time = `(${data?.beginTime} - ${data?.endTime})`;
          setClassDate(`${date} ${time}`);
          setClassCountdown(findCountdownDuration(date, data?.beginTime));
        }
      });
    });
  }, [history]);

  return (
    <Container>
      <StyledCard fluid>
        <StyledCard.Content>
          <p className="left aligned">Your next class is coming in:</p>
          <StyledHeader as="h1" textAlign="left">
            {classCountdown}
          </StyledHeader>
          <p className="left aligned">{classDate}</p>
          <StyledButton
            className="right floated"
            onClick={() =>
              handleButtonClick("https://discord.gg/WqqxTCmsQv")
            }
          >
            Open Discord
          </StyledButton>
          {/* {haveCourse && !haveClass && (
            <StyledInvertedButton
              className="right floated"
              onClick={() => history.push("/register-class")}
            >
              Register Class Schedule
            </StyledInvertedButton>
          )} */}
          {!haveCourse && (
            <StyledInvertedButton
              className="right floated"
              onClick={() => history.push("/register-regular")}
            >
              Register Regular Course
            </StyledInvertedButton>
          )}
          {/* {haveCourse && (
            <StyledInvertedButton
              className="right floated"
              onClick={() => history.push("/register-regular")}
            >
              Change Course
            </StyledInvertedButton>
          )} */}
        </StyledCard.Content>
      </StyledCard>
    </Container>
  );
};

export default NextClassReminder;
