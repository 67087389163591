import React, { useState, useEffect } from "react";

import Footer from "../../components/common/Footer";
import Navbar from "../../components/common/Navbar/index";
import SessionBoxMember from "components/pages/MemberMaterial/SessionBoxMember";
import { Wrapper } from "./styles";
import { getMemberMaterials, getClass } from "../../api/member.js";

const MemberMaterialPage = () => {
    const [material, setMaterial] = useState("");
    const [classId, setClassId] = useState("");

    const SESSION_DATA = [
        {
            id: 1,
            session: 1,
            title: "Introduction to UI/UX",
            filePath: "https://youtu.be/dQw4w9WgXcQ?si=gqlOjOJahS7_oMRi",
            recordingPath: "https://youtu.be/dQw4w9WgXcQ?si=gqlOjOJahS7_oMRi",
            key: 1,
        },
        {
            id: 2,
            session: 2,
            title: "Design Principles",
            filePath: "https://youtu.be/dQw4w9WgXcQ?si=gqlOjOJahS7_oMRi",
            recordingPath: "https://youtu.be/dQw4w9WgXcQ?si=gqlOjOJahS7_oMRi",
            key: 2,
        },
        {
            id: 3,
            session: 3,
            title: "Introduction to Figma",
            filePath: "https://youtu.be/dQw4w9WgXcQ?si=gqlOjOJahS7_oMRi",
            recordingPath: "https://youtu.be/dQw4w9WgXcQ?si=gqlOjOJahS7_oMRi",
            key: 3,
        },
        {
            id: 4,
            session: 4,
            title: "UX Laws",
            filePath: "https://youtu.be/dQw4w9WgXcQ?si=gqlOjOJahS7_oMRi",
            recordingPath: "https://youtu.be/dQw4w9WgXcQ?si=gqlOjOJahS7_oMRi",
            key: 4,
        },
    ];

    // useEffect(() => {
    //     getClass().then((res) => {
    //         setClassId(res.data.data[0].id);
    //         classId &&
    //             getMemberMaterials(classId).then((res) => {
    //                 if (
    //                     typeof res.data.data != "object" ||
    //                     res.data.data.length === 0
    //                 )
    //                     return setMaterial(false);
    //                 setMaterial(res.data.data);
    //             });
    //     });
    // }, [classId]);

    return (
        <>
            <Navbar title="Material" />
            <Wrapper>
                <div className="header">
                    <h1>Material</h1>
                </div>
                <div className="contents">
                    {SESSION_DATA ? (
                        SESSION_DATA?.map((log, index) => {
                            return (
                                <SessionBoxMember
                                    materialId={log.id}
                                    session={log.session}
                                    topic={log.title}
                                    materialLink={log.filePath}
                                    recordingLink={log.recordingPath}
                                    key={index}
                                />
                            );
                        })
                    ) : (
                        <h4 className="no-materials">You have no ongoing material at the moment.</h4>
                    )}
                </div>
            </Wrapper>
            <Footer />
        </>
    );
};

export default MemberMaterialPage;
