import styled from "styled-components";

export const Wrapper = styled.div`
    box-shadow: 0px 2px 10px 0px #00000040;
    width: 100%;
    padding: 2rem;
    border-radius: 1rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    *{
        background: white;
        font-family: Montserrat;
    }
    h2{
        margin-bottom: .5rem;
        font-size: 24px;
        font-weight: 700;
    }
    p{
        margin-bottom: .2rem;
        font-size: 14px;
        font-weight: 500;
    }
    .button-flex {
        display: flex;
        gap: 10px;
    }
    a{
        border: none;
        background-color: #31426E;
        color: white;
        padding: 8px 32px;
        border-radius: 8px;
        margin-top: auto;
        margin-bottom: auto;
    }
`