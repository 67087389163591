import Footer from "components/common/Footer";
import Navbar from "components/common/Navbar";
import RegisterTrial from "components/pages/RegisterTrial";

const RegisterRegularPage = () => {
  return (
    <>
      <Navbar title="Register Regular Class" />
      <RegisterTrial />
      <Footer />
    </>
  );
};

export default RegisterRegularPage;
