import { useEffect, useState } from "react";
import { ROLE_LOCAL_STORAGE_KEY } from "constants/localstorage";

const useGetUser = () => {
  const [user, setUser] = useState<string | null>(null);

  useEffect(() => {
    setUser(localStorage.getItem(ROLE_LOCAL_STORAGE_KEY));
  }, [setUser]);

  return { user };
};

export default useGetUser;
