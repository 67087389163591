export const blue = '#31426e'
export const mainBlue = '#31426e'
export const white = '#fff'
export const mainWhite = '#f7f7f7'
export const mainBlack = '#333333'
export const secondaryBlack = '#828282'
export const secondaryWhite = '#e0e0e0'
export const mainblue = '#31426e'
export const mainbluehover = '#5a688b'
export const mainblueclick = '#253254'
export const orange = '#d97441'
export const orangehover = '#e78f55'
export const orangeclick = '#a55831'
export const desaturatedBlue = '#5ed8f8'
export const errorRed = '#ce1212'
export const blueGray = '#949cb2'
