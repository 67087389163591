import styled from "styled-components";
import { Form } from "formik";
import { Input, Select } from "formik-semantic-ui-react";

export const Wrapper = styled.div`
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.4);
    color: white;
    *{
        background: white;
    } 
    .buttons button{
        padding: .7rem 2.5rem;
        border-radius: .5rem;
        margin-top: 1rem;
    }
    .cancel-btn{
        background: transparent;
        margin-right: .5rem;
        border: 2px solid #31426E;
        font-weight: 600;
        color: #31426E;
    }
    .submit-btn{
        background: #31426E;
        margin-left: .5rem;
        font-weight: 600;
        color: white;
    }
    .submit-btn:hover{
        background: #3D548D;
        margin-left: .5rem;
        color: white;
    }
    button:hover, input:hover, select:hover, option:hover{
        cursor: pointer;
    }
`

export const FormStyled = styled(Form)`
        background: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #333333;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 3rem;
        border-radius: 1rem;
        .close{
            position: absolute;
            right: 0;
            top: 0;
            border: none;
            background: transparent;
            padding: .5rem 1rem;
            font-size: 1.5rem;
        }
        label{
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-top: 1rem;
        }
`

export const InputStyled = styled(Input)`
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid black;
        border-radius: .3rem;
        font-size: 1rem;
        input{
            width: 25rem;
        }
`

export const SelectStyled = styled(Select)`
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: .3rem;
        font-size: 1rem;
        div[name="session"]{
            border: 1px solid black !important;
            width: 25rem;
        }
`