import { Image, Wrapper, Button } from "./style";
import Logo from "images/logo.png";
import { useHistory } from "react-router";

const LandingNavbar = () => {
  const history = useHistory();

  const handleLogoClick = () => {
    history.push("/");
  };

  const handleLoginClick = () => {
    history.push("/login");
  };

  return (
    <Wrapper>
      <Image src={Logo} alt="Logo" onClick={() => handleLogoClick()} />
      <Button onClick={() => handleLoginClick()}>Login</Button>
    </Wrapper>
  );
};

export default LandingNavbar;
