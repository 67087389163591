import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Container, Header } from "semantic-ui-react";

import { getAttendance, getClassById } from "../../../../api/admin";
import ClassAttendanceModal from "./ClassAttendanceModal";

const ClassAttendance = () => {
  const [classInfo, setClassInfo] = useState([]);
  const [attendance, setAttendance] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    getAttendance().then((res) => {
      setAttendance(res.data.data);
    });
    getClassById(id).then((res) => {
      setClassInfo(res.data);
    });
  }, [id]);

  const handleSessionClick = (data) => {
    setIsModalOpen(true);
    setModalContent(data);
  };

  const renderSessionList = attendance.map((data) => (
    <div
      className="session"
      key={data.id}
      onClick={() => handleSessionClick(data)}
    >
      <div className="session-name">Session {data.session ?? "X"}</div>
      <div className="session-date">{data.date}</div>
    </div>
  ));

  return (
    <Container className="class-attendance">
      <Header as="h1" className="class-attendance-header">
        {classInfo && classInfo.praetorianName}&apos;s Class
      </Header>
      <div className="class-attendance-subheader">
        {classInfo && classInfo.courseId} - Every {classInfo && classInfo.day},&nbsp;
        {classInfo && classInfo.beginTime} - {classInfo && classInfo.endTime}
      </div>
      <div className="session-container">{renderSessionList}</div>
      <ClassAttendanceModal
        modalOpen={isModalOpen}
        handleClose={() => {
          setIsModalOpen(false);
        }}
        modalContent={modalContent}
      />
    </Container>
  );
};

export default ClassAttendance;
