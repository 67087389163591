import { Button, Logout, Menu, Other, Wrapper } from "../style";
import { useHistory } from "react-router";
import { isTokenExist } from "utils/http-api";
import { Link } from "react-router-dom";

const AdminNavbar = () => {
  const history = useHistory();

  const handleLogoutClick = () => {
    localStorage.removeItem("accessToken");
    history.push("/");
  };

  return (
    <Wrapper>
      <Menu>
        <Link to="/admin/praetorian">Praetorian List</Link>
        <Link to="/admin/course">Course List</Link>
        <Link to="/admin">Calendar</Link>
        <Link to="/admin/class">Class List</Link>
        <Link to="/admin/member">Member List</Link>
      </Menu>
      <Other>
        <Link to="/admin">Settings</Link>
        {isTokenExist && (
          <Button onClick={() => handleLogoutClick()}>Logout</Button>
        )}
      </Other>
    </Wrapper>
  );
};

export default AdminNavbar;
