import MemberAttendance from '../MemberAttendance'
import { CloseButton, Members, OuterWrapper, Wrapper } from './styles'

function AttendanceModal({ date, classId, praetoName, schedule, setShowModal, attendanceInfo }) {
  return (
    <OuterWrapper>
      <Wrapper>
        <CloseButton onClick={() => setShowModal(false)}>X</CloseButton>
        <h1>{date}</h1>
        <hr />
        <h2>{classId.toUpperCase()} - {praetoName}</h2>
        <h3>{classId.toUpperCase().slice(0, 2)} • {schedule}</h3>
        <hr />
        <Members>
          {attendanceInfo?.map(member => {
            return <MemberAttendance key={member?.id} id={member?.userId} name={member?.name} status={(member?.status === "present" ? "Present" : "Absent")} />
          })}
        </Members>
      </Wrapper>
    </OuterWrapper>
  )
}

export default AttendanceModal