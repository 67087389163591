import styled from "styled-components";
import { blue, white } from "styles/color";
import { spacingM, spacingXxl2 } from "styles/spacing";

export const Container = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5rem 10rem;

  @media only screen and (max-width: 768px) {
    padding: 3rem 0;
    height: 100%;
  }
`;

export const Header = styled.h1`
  color: ${blue};
  font-size: 48px;
  margin-bottom: ${spacingM};
  font-weight: 600;
  font-family: "Montserrat";
  text-align: center;

  @media only screen and (max-width: 768px) {
    font-size: 24px;
    width: 100%;
  }
`;

export const Description = styled.p`
  font-size: 18px;
  font-weight: 400;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${spacingXxl2};

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ClassWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${spacingXxl2};

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const Card = styled.div`
  border-radius: 6px;
  border: ${({ active }) => (active ? `5px solid ${blue}` : "1px solid black")};
  margin: 0.5rem;
  padding: 1rem 2rem;
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    width: 80%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
  width: 50%;

  @media only screen and (max-width: 768px) {
    width: 80%;
    align-items: center;
    text-align: center;
  }
`;

export const Title = styled.h3`
  font-weight: 700;
  font-size: 24px;
  font-family: "Montserrat";
  margin-bottom: ${spacingXxl2};
`;

export const ImageWrapper = styled.div`
  display: flex;
  width: 50%;

  img {
    width: 100%;
  }

  @media only screen and (max-width: 768px) {
    width: 80%;
  }
`;

export const Button = styled.div`
  cursor: pointer;
  background-color: ${({ checked }) => (checked ? blue : "#949cb2")};
  margin-left: auto;
  color: ${white};
  padding: 0.5rem 2.5rem;
  border-radius: 6px;
  margin-top: ${spacingXxl2};

  &:hover {
    background-color: ${({ checked }) => (checked ? "#5a688b" : "")};
  }

  &:active {
    background-color: ${({ checked }) => (checked ? "#253254" : "")};
  }

  @media only screen and (max-width: 768px) {
    margin-left: 0;
  }
`;
