import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
`;

export const Image = styled.img`
  display: block;
  width: 80px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
`;

export const WelcomeText = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: #31426e;
  padding: 1rem 0;
  text-align: center;
`;

export const RoleText = styled.div`
  font-size: 18px;
  color: #828282;
  text-align: center;
`;
