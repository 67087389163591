import { getUserInfo } from "api/auth";
import React, { useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Link } from "react-router-dom";
import { getAttendanceCount } from "../../../../api/member";
import {
  ChartContainer,
  GridContainer,
  StatsContainer,
  StyledCard,
  StyledCardAction,
  StyledCardContent,
  StyledHeader,
} from "./styles";

const AttendanceChart = () => {
  const [sessionDoneCount, setSessionDoneCount] = useState(0);
  const [totalAbsentCount, setTotalAbsentCount] = useState(0);
  const [totalSessionCount, setTotalSessionCount] = useState(0);
  const AttendancePercentage =
    sessionDoneCount !== 0 ?
    Math.abs(Math.round((totalAbsentCount / totalSessionCount) * 100) - 100)
    : 0;
  
  useEffect(() => {
    let bnccId;
    getUserInfo().then((res) => {
      bnccId = res.data.id;
      getAttendanceCount(bnccId).then((res) => {
        if (res.data.data) {
          setSessionDoneCount(res.data.data[0].sessionDone ?? 0);
          setTotalAbsentCount(res.data.data[0].totalAbsent ?? 0);
          setTotalSessionCount(res.data.data[0].totalSession ?? 0);
        }
      });
    });
  }, []);
  
  return (
    <StyledCard>
      <StyledCardContent>
        <StyledHeader>Attendance</StyledHeader>
        <GridContainer>
          <ChartContainer>
            <CircularProgressbar
              value={AttendancePercentage}
              text={`${AttendancePercentage}%`}
              styles={buildStyles({
                rotation: 0.5,
                strokeLinecap: "butt",
                pathColor: `#31426E`,
                textColor: "#31426E",
                trailColor: "#d6d6d6",
                backgroundColor: "#3e98c7",
              })}
            />
          </ChartContainer>
          <StatsContainer width={5} className="align-items-center">
            <div>
              <p>Total Session</p>
              <p>Session Done</p>
              <p>Total Absence</p>
            </div>
            <div>
              <p>{totalSessionCount}</p>
              <p>{sessionDoneCount}</p>
              <p>{totalAbsentCount}</p>
            </div>
          </StatsContainer>
        </GridContainer>
      </StyledCardContent>
      <StyledCardAction>
        <Link to={"/attendance/details"}>
          <p className="right aligned">View Details</p>
        </Link>
      </StyledCardAction>
    </StyledCard>
  );
};

export default AttendanceChart;
