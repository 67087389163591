import Navbar from 'components/common/Navbar'
import React, { useState } from 'react'

import Footer from '../../components/common/Footer'
import FetchProject from 'components/pages/MemberProject/FetchProject'
import HistoryModal from 'components/pages/MemberProject/HistoryModal'
import UploadModal from 'components/pages/MemberProject/UploadModal'

const MemberProjectPage = (props) => {
  const [historyState, setHistoryState] = useState({ data: [] })
  const [historyBool, setHistoryBool] = useState(false)
  const [uploadState, setUploadState] = useState({ data: [] })
  const [uploadBool, setUploadBool] = useState(false)
  const [project, setProject] = useState(false)

  const fetchHistory = (val) => {
    setHistoryState({ data: val })
    setHistoryBool(true)
  }

  const historyClose = () => {
    setHistoryState({ data: [] })
    setHistoryBool(false)
  }

  const fetchUpload = (val) => {
    setUploadState({ data: val })
    setUploadBool(true)
  }

  const uploadClose = () => {
    setUploadState({ data: [] })
    setUploadBool(false)
  }

  const checkProject = (val) => {
    setProject(val)
  }

  return (
    <>
      <Navbar title="" />
      <div className="popup">
        <HistoryModal
          data={historyState.data}
          bool={historyBool}
          close={historyClose}
        />
        <UploadModal
          data={uploadState.data}
          bool={uploadBool}
          close={uploadClose}
        />
      </div>
      <div className="project-main">
        <div className={'title ' + (project ? '' : 'hidden')}>Project</div>
        <div className="project-container">
          <FetchProject
            onHistory={fetchHistory}
            onUpload={fetchUpload}
            checkProject={checkProject}
          />
        </div>
      </div>
      <Footer />
    </>
  )
}

export default MemberProjectPage
