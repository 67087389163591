const HistoryModal = (props) => {

    function showDate(date){
        let d = date.getDate();
        if(d<10)
            d='0'+d;
        let m = date.toLocaleString('en-us', { month: 'long' });
        let y = date.getFullYear();
        let hour = date.getHours();
        if(hour<10)
            hour='0'+hour;
        let min = date.getMinutes();
        if(hour<10)
            min='0'+min;
        return d +' '+ m +' '+ y +' ('+ hour +':'+ min +')';
    }

    const SubmissionLoop =
        props.data.map((d, index) => 
            <div key={index} className="list">
                <div>
                    <div className="name" >{d.name}</div>
                    <div className="file">File: {d.file}</div>
                    <div className="date" >{showDate(d.time)}</div>
                </div> 
                <div className="download-btn">Download</div>
            </div>
        )

    return(
        <div className={`overlay ${props.bool ? '' : 'hidden'}`}>
            <div className="modal">
                <button className="close-btn" onClick={props.close}>x</button>
                <div className="content">
                    <div className="title">Submission History</div>
                    {(!Object.keys(props.data).length)? 
                        <div className="none">You haven’t submitted anything yet</div> :
                        SubmissionLoop
                    }
                </div>
            </div>
        </div>
    );
}

export default HistoryModal;
