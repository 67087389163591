import React, { useState } from "react";
import { useEffect } from "react";
import ProfileIcon from "images/profileIcon.svg";
import {
    Card,
    Content,
    Field,
    Header,
    Image,
    Section,
    Wrapper,
    Button,
    FieldShort,
} from "./style";
import { getUserInfo } from "../../../api/auth";
import ChangePassword from "./ChangePassword";

const Profile = () => {
    const [memberProfile, setMemberProfile] = useState("");
    const [open, setOpen] = useState(false);

    useEffect(() => {
        getUserInfo().then((res) => {
            setMemberProfile(res.data);
        });
    }, []);

    return (
        <Section>
            <ChangePassword open={open} closeModal={() => setOpen(false)} />
            <Card>
                <Image src={ProfileIcon} alt="Profile Icon" className="image" />
                <form>
                    <label>Display Name</label>
                    <FieldShort>{memberProfile.name}</FieldShort>
                    <label>BNCC ID</label>
                    <FieldShort>{memberProfile.id}</FieldShort>
                    <Button
                        onClick={(e) => {
                            setOpen(true);
                            e.preventDefault();
                        }}
                    >
                        Change Password
                    </Button>
                </form>
            </Card>
            <Wrapper>
                <Header>User Profile</Header>
                <Content>
                    <label>Full Name</label>
                    <Field>{memberProfile.name}</Field>
                    <label>LnT Course</label>
                    <Field>{memberProfile.courseName}</Field>
                    <label>NIM</label>
                    <Field>{memberProfile.nim}</Field>
                    <label>Major</label>
                    <Field>{memberProfile.majorName}</Field>
                </Content>
            </Wrapper>
        </Section>
    );
};

export default Profile;
