import { Divider, Container, Heading, ConfirmText, Cancel, Logout, Illus, ConfirmBtn } from "./style";
import SuccessDialogIllus from 'images/SuccessDialogIllus.svg';


const handleLogout = (localStorage) => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('role');
}

const SuccessDialog = ({history,isConfirm})=>{
    if(!isConfirm) return null
    return <>
        <Divider>
            <Container>
                <Illus src={SuccessDialogIllus} alt="Success"/>
                <Heading>Log Out Success</Heading>
                <ConfirmText>We can&apos;t wait to see you again!</ConfirmText>
                <ConfirmBtn onClick={() => history.push('/')}>
                    Confirm</ConfirmBtn>
            </Container>
        </Divider>
        
    </>
}

const LogoutConfirmation = ({localStorage, history, isOpen, onClose, isConfirm, onConfirm}) => { 
    if(!isOpen) return null
    return <>
        <Divider>
            <Container>
                <Heading>Log Out Confirmation</Heading>
                <ConfirmText>Are you sure you want to log out?</ConfirmText>
                <div>
                    <Cancel onClick={onClose}>Cancel</Cancel>
                    <Logout onClick={() =>{
                        handleLogout(localStorage)
                        onConfirm()
                    }}>Logout</Logout>
                </div>
            </Container>
        </Divider>
        
        <SuccessDialog history = {history} isConfirm = {isConfirm}/>
    </>
}

export default LogoutConfirmation;