import styled from 'styled-components';

export const Wrapper = styled.div`
    text-align: center;
`

export const Heading = styled.h1`
    font-size: 48px;
    font-weight: 700;
    line-height: 72px;
    text-align: center;
`