import styled from "styled-components";
import { blue, mainBlue, mainWhite, white } from "styles/color";

export const Wrapper = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 4rem;
  background: white;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  height: 4rem;
  position: relative;

  a {
    padding: 0.8rem 2rem;
    color: ${blue};

    &:hover {
      font-weight: bold;
    }
  }

  @media only screen and (max-width: 1024px) {
    padding: 1rem 2rem;
  }

  @media only screen and (max-width: 992px) {
    padding: 1rem;
  }
`;

export const Image = styled.img`
  position: relative;
  cursor: pointer;
  height: 2.5rem;
`;

export const Button = styled.div`
  border: 1px solid #31426e;
  border-radius: 6px;
  color: ${white};
  background-color: ${mainBlue};
  padding: 8px 40px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    background-color: ${white};
    color: ${mainBlue};
  }
`;
