import React, { useState, useEffect } from "react";
import { Wrapper } from "./styles";
import { Formik } from "formik";
import {
    getPraetorianClass,
    deletePraetorianMaterial,
    getPraetorianMaterialByClassId,
} from "api/praetorian";
import { FormStyled } from "./styles";
import SuccessModal from "components/common/Modal/SuccessModal/index";
import successDelete from "images/avatar/successDelete.svg";
import { ERROR_CODE } from "constants/error";

const PRAETORIAN_STARTING_TERM = 3;

export default function DeleteMaterial({ closeModal, materialId }) {
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [materialDeleted, setMaterialDeleted] = useState(false);

    const initialValues = {
        id: materialId,
    };

    const onFormSubmit = (values) => {
        let classId;
        getPraetorianClass().then((res) => {
            if (res.data.code === ERROR_CODE.FORBIDDEN) {
                alert("You are not a Praetorian");
                return closeModal();
            } else {
                classId = res.data.data.filter((data) => data.termId === PRAETORIAN_STARTING_TERM);
            }
            if (classId)
                getPraetorianMaterialByClassId(classId[0].id).then((res) => {
                    let materialsId = res.data.data.filter((data) => data.id === values.id);
                    deletePraetorianMaterial(materialsId[0].id).then(() => {
                        setShowSuccessModal(true);
                    });
                })
        });
    };

    useEffect(() => {
        if (materialDeleted) {
            window.location.reload();
        }
    }, [materialDeleted]);

    return (
        <Wrapper>
            <Formik
                initialValues={initialValues}
                onSubmit={(values) => {
                    onFormSubmit(values);
                }}
            >
                {(props) => (
                    <FormStyled>
                        <button className="close" onClick={closeModal}>
                            x
                        </button>
                        <h1>Delete Confirmation</h1>
                        <p>Are you sure you want to delete this material?</p>
                        <div className="buttons">
                            <button
                                type="reset"
                                className="cancel-btn"
                                onClick={closeModal}
                            >
                                Cancel
                            </button>
                            <button type="submit" className="submit-btn">
                                Delete
                            </button>
                        </div>
                    </FormStyled>
                )}
            </Formik>
            {showSuccessModal && (
                <SuccessModal
                    openSM={showSuccessModal}
                    closeSM={() => setMaterialDeleted(true)}
                    image={successDelete}
                    heading="Material Successfully Deleted!"
                />
            )}
        </Wrapper>
    );
}
