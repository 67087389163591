import Navbar from "components/common/Navbar";
import { Header, Wrapper, Description } from "./style";

const ErrorPage = ({ errorCode, message }) => (
  <>
    <Navbar />
    <Wrapper>
      <Header>{errorCode}</Header>
      <Description>{message}</Description>
    </Wrapper>
  </>
);

export default ErrorPage;
